// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.variantEditorHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 2px solid #e0e0e0;
}`, "",{"version":3,"sources":["webpack://./src/features/Editor/features/variantEditor/components/VariantEditorHeader/VariantEditorHeader.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,gCAAA;AACJ","sourcesContent":[".variantEditorHeader {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 20px;\n    border-bottom: 2px solid #e0e0e0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
