/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompositionElementsDto } from '../models/CompositionElementsDto';
import type { UpdateCompositionElementsDto } from '../models/UpdateCompositionElementsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompositionElementsService {

    /**
     * Gets the composition details given a composition id.
     * @param compositionId 
     * @returns CompositionElementsDto Success
     * @throws ApiError
     */
    public static getCompositionElements(
compositionId: string,
): CancelablePromise<CompositionElementsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositions/{compositionId}/compositionelements',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update composition elements.
     * @param requestBody 
     * @returns CompositionElementsDto Success
     * @throws ApiError
     */
    public static updateCompositionElements(
requestBody?: UpdateCompositionElementsDto,
): CancelablePromise<CompositionElementsDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compositions/elements',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
