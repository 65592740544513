import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleNodes } from '@fortawesome/pro-regular-svg-icons';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@lbc-toolkit/spinner';
import { Text } from '@lbc-toolkit/text';
import { Card, IconButton, Skeleton } from '@mui/material';
import { useState } from 'react';
import { VariantOptions } from '../../../services/modelmanager';
import useIfcExport from '../hooks/useIfcExport';
import VariantSelector from './VariantSelector';

export type ViewerToolbarProps = {
	compositionId: string;
	isLite?: boolean;
	compositionName?: string;
	isLoading?: boolean;
	variantOptions?: VariantOptions[];
	updateVariantOptions?: (newOptions: VariantOptions[]) => void;
};

const ViewerToolbar = ({
	compositionId,
	isLite = false,
	compositionName,
	isLoading = false,
	variantOptions = [],
	updateVariantOptions = () => {},
}: ViewerToolbarProps) => {
	const [isConfigureVariante, setIsConfigureVariante] = useState(false);

	const { downloadIfc, isPending: pendingDownloadIfc } = useIfcExport(compositionId, variantOptions, compositionName);

	return (
		<>
			<div className='flex gap-3' style={{ paddingBottom: '10px' }}>
				{!isLite && (
					<Card sx={{ padding: '8px 16px' }}>
						{!compositionName ? (
							<Skeleton height={48} width={120} />
						) : (
							<Text type='h2'>{compositionName}</Text>
						)}
					</Card>
				)}
				<Card>
					<IconButton
						disabled={isLoading}
						color='inherit'
						className='h-16 w-16 !rounded-none'
						onClick={() => setIsConfigureVariante(!isConfigureVariante)}
					>
						<FontAwesomeIcon icon={faCircleNodes as IconProp} size='lg' />
					</IconButton>
				</Card>
				{!isLite && (
					<Card>
						<IconButton
							disabled={isLoading || pendingDownloadIfc}
							color='inherit'
							className='h-16 w-16 !rounded-none'
							onClick={() => downloadIfc()}
						>
							{pendingDownloadIfc ? <Spinner spin /> : <FontAwesomeIcon icon={faDownload as IconProp} />}
						</IconButton>
					</Card>
				)}
			</div>
			{isConfigureVariante && (
				<Card sx={{ p: 1 }}>
					<VariantSelector
						variantOptions={variantOptions ?? []}
						updateState={updateVariantOptions}
						isLoading={isLoading}
					/>
				</Card>
			)}
		</>
	);
};

export default ViewerToolbar;
