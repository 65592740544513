import { useTranslate } from "@tolgee/react";
import AddInstanceModal from "../../../../components/AddInstanceModal";
import { ModalProps } from "react-bootstrap";
import useVariantEditor from "../../hooks/useVariantEditor";
import useCompositions from "../../../../../../hooks/useCompositions";
import { ElementInstanceDto } from "../../../../../../services/composer";

type AddCompositionInstanceModalProps = {
    onSave: (data: ElementInstanceDto) => void;
} & ModalProps;

const AddCompositionInstanceModal = ({show, onClose, onSave}: AddCompositionInstanceModalProps) => {
    const { compositionElements } = useVariantEditor();
    const { t } = useTranslate();
    const {compositions, isLoading} = useCompositions();

    const existingIds = new Set(compositionElements?.contains?.map(component => component.instanceOfId));

    const availableCompositions = compositions?.filter(composition => 
        !existingIds.has(composition.id)
    ) ?? [];

    return <AddInstanceModal 
        label={t('addCompositionInstance', "Add composition instance")}
        elementBases={availableCompositions}
        isLoading={isLoading}
        onClose={onClose}
        show={show}
        onSave={onSave}
    />
}


export default AddCompositionInstanceModal;