import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrid } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Config from '../../../configuration/config';

const NavigationGrid = ({}) => {
	const [anchorE1, setAnchorE1] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorE1);
	const [warehouseAddress] = useState<string>(Config.WarehouseClientUrl || '');
	const [catalogAddress] = useState<string>(Config.CatalogClientUrl || '');

	const optionsDictionary = {
		'Warehouse': warehouseAddress,
		'Catalog': catalogAddress,
	};
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorE1(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorE1(null);
	};

	return (
		<>
			<IconButton
				id='long-button'
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup='true'
				onClick={handleClick}
			>
				<FontAwesomeIcon icon={faGrid as IconProp} />
			</IconButton>
			<Menu
				id='long-menu'
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorE1}
				open={open}
				onClose={handleClose}
			>
				{Object.entries(optionsDictionary).map(([option, address]) => (
					<MenuItem key={option} onClick={handleClose}>
						<Button href={address} target='_blank' variant='text'>
							{option}
						</Button>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default NavigationGrid;
