import { Component } from "rete";
import { InputSocket, OutputSocket } from "./CustomSocket";
import { ElementInstanceDto, SocketDto } from "../../../../../../services/composer";

/**
 * Represents a component for an element instance in the Rete graph.
 */
export default class ElementInstanceComponent extends Component {
  constructor() {
    super('ElementInstance');
  }

  /**
   * Builds the node by adding input and output sockets based on the element instance data.
   * 
   * Required override for the Rete.Component class.
   * @param node The node to be built.
   * @returns The built node.
   */
  builder(node: any) {
    var elementInstance = node.data.elementInstance as ElementInstanceDto;

    var inputs = elementInstance.sockets?.filter((socket: SocketDto) => socket.direction === "In");
    var outputs = elementInstance.sockets?.filter((socket: SocketDto) => socket.direction === "Out");

    inputs?.forEach((inputSocket: SocketDto) => {
      var input = new InputSocket(inputSocket.id!, inputSocket.connectorName!);
      node.addInput(input);
    })

    outputs?.forEach((outputSocket: SocketDto) => {
      var output = new OutputSocket(outputSocket.id!, outputSocket.connectorName!);
      node.addOutput(output);
    })
    
    return node;
  }

  /**
   * Worker function for processing the node in the Rete graph.
   * Required override for the Rete.Component class.
   * @param node - The node to be processed.
   * @param inputs - The inputs for the node.
   * @param outputs - The outputs for the node.
   */
  worker(node: any, inputs: any, outputs: any) {
    outputs['connector'] = node.data.num;
    inputs['connector'] = node.data.num;
  }
}