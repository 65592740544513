import { faArrowRightToArc, faGear, faGears,faTrash } from '@fortawesome/pro-solid-svg-icons';
import Button from 'react-bootstrap/esm/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styles
import "./Node.scss";
import { ElementBaseDiscriminator, ElementInstanceDto } from '../../../../services/composer';

type NodeProps = {
	elementInstance: ElementInstanceDto;
	isReadonly: boolean;
	onRemove: () => void;
	onNavigate?: (id: string) => void;
	className?: string;
	children: JSX.Element | JSX.Element[] | string | undefined;
};

const Node = ({ elementInstance, isReadonly, onRemove, onNavigate, children: sockets, className }: NodeProps) => {
	return (
		<div className={'node ' + className}>
			<div className='node-action-buttons'>
				{onNavigate != undefined &&
					(elementInstance.instanceOfType === ElementBaseDiscriminator.Composition ||
						elementInstance.instanceOfType === ElementBaseDiscriminator.CompositionVariant) && (
						<Button className='node-edit-btn btn-sm' onClick={() => onNavigate(elementInstance.instanceOfId ?? '')}>
							<FontAwesomeIcon className='node-button-icon' size='xs' icon={faArrowRightToArc} />
						</Button>
					)}
				{!isReadonly && (
					<Button className='node-delete-btn btn-sm' onClick={onRemove}>
						<FontAwesomeIcon className='node-button-icon' size='xs' icon={faTrash} />
					</Button>
				)}
			</div>
			<div className='node-content'>
				<div className='node-title'>
					{elementInstance.instanceOfType === 'Component' ? (
						<FontAwesomeIcon size='sm' color='black' icon={faGear} />
					) : (
						<FontAwesomeIcon size='sm' color='black' icon={faGears} />
					)}
					{" " + elementInstance.name}
				</div>
				{sockets}
			</div>
		</div>
	);
};

export default Node;
