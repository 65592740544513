// Services
import { DataType, SimplePropertyDto } from '../../../../../services/warehouse';

// React
import { useState } from 'react';

interface SimplePropertyInput {
	property: SimplePropertyDto;
	onChange: (id: string, value: any) => void;
	initValue: any;
	editMode: boolean;
}

const SimplePropertyInput = ({ property, onChange, initValue, editMode }: SimplePropertyInput) => {
	const [value, setValue] = useState<number | string | boolean>(initValue ?? '');

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let newValue: any;
		if (property.dataType === DataType.Integer) {
			newValue = parseInt(e.target.value, 10);
		} else if (property.dataType === DataType.Boolean) {
			newValue = e.target.checked;
		} else {
			newValue = e.target.value;
		}
		setValue(newValue);
		onChange(property.id!, newValue);
	};

	const renderInput = () => {
		switch (property.dataType) {
			case DataType.Integer:
				return (
					<input type='number' value={value.toString()} disabled={!editMode} onChange={handleInputChange} />
				);
			case DataType.Boolean:
				return (
					<input type='checkbox' checked={Boolean(value)} disabled={!editMode} onChange={handleInputChange} />
				);
			default:
				return <input value={value.toString()} disabled={!editMode} onChange={handleInputChange} />;
		}
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
			<span style={{ marginRight: '8px' }}>{property.name}</span>
			{renderInput()}
		</div>
	);
};
export default SimplePropertyInput;
