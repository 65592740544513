import { Button as LbcButton } from '@lbc-toolkit/button';
import { useTranslate } from '@tolgee/react';
import { Modal } from 'react-bootstrap';
import { hideConfirmationDialog } from '../../../context/appReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import useSaveCompositionBuild from '../../../hooks/useSaveCompositionBuild';
import { useEffect } from 'react';

const LeaveConfirmationDialog = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const selectedComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const confirmation = useAppSelector((state: RootState) => state.app.confirmation);
	const { show, title, message, handleConfirm, confirmLabel, closeLabel } = confirmation;

	const {saveCompositionBuild, isPending} = useSaveCompositionBuild();

	function handleClose() {
		dispatch(hideConfirmationDialog());
	}

	useEffect(() => {
		if(!isPending){
			if(handleConfirm)handleConfirm();
			handleClose();
		}
	}, [isPending]);

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			{message && <Modal.Body>{message}</Modal.Body>}
			<Modal.Footer>
				<LbcButton type='secondary' onClick={handleClose} label={closeLabel ?? t('cancel')} />
				<LbcButton
					type='secondary'
					onClick={() => {
						if (handleConfirm) handleConfirm();
						handleClose();
					}}
					label={confirmLabel ?? t('confirm')}
				/>
				<LbcButton 
					type="primary"
					label={t('save')}
					animation={isPending && "spin"}
					icon={isPending ? faSpinner : faSave}
					onClick={() => {
						saveCompositionBuild(selectedComposition?.id ?? '');
					}}
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default LeaveConfirmationDialog;
