import { PropertyDto } from '../../../../services/catalog';
import {
	PropertyCollectionDto,
	PropertyEntryDto,
	PropertySetEntryDto,
	PropertyUsageType,
} from '../../../../services/warehouse';
import PropertyGridPSet from './PropertyGridPSet';
import { useTranslate } from '@tolgee/react';
import './PropertyGrid.scss';

type PropertyGridProps = {
	editMode: boolean;
	propertyCollection: PropertyCollectionDto;
	propertyType: PropertyUsageType;
	selectedProperty: PropertyDto | null;
	onChange: (value: PropertyCollectionDto) => void;
	onSelectProperty?: (value: PropertyDto | null) => void;
	getData: (data: boolean) => void;
};

const PropertyGrid = ({
	editMode,
	propertyCollection,
	onChange,
	propertyType,
	selectedProperty,
	onSelectProperty,
	getData,
}: PropertyGridProps) => {
	const { t } = useTranslate();

	const onValueChanged = (entry: PropertyEntryDto) => {
		const tempPropertySetEntries = propertyCollection.propertySetEntries?.map((setEntry: PropertySetEntryDto) => {
			const updatedPropertyEntries = setEntry.propertyEntries?.map((element) => {
				if (entry.property?.id === element.property?.id) {
					return {
						...element,
						value: entry.value,
					};
				}
				return element;
			});

			return {
				...setEntry,
				propertyEntries: updatedPropertyEntries,
			};
		});
		onChange({
			classificationId: propertyCollection.classificationId,
			propertySetEntries: tempPropertySetEntries,
		} as PropertyCollectionDto);
	};

	return (
		<table className='table table-bordered'>
			<tbody>
				{propertyCollection?.propertySetEntries?.length ? (
					propertyCollection.propertySetEntries.map((propertySet: PropertySetEntryDto, index) => (
						<PropertyGridPSet
							key={index}
							editMode={editMode}
							propertySet={propertySet}
							selectedPropertyType={propertyType}
							selectedPropertyId={selectedProperty?.id ?? ''}
							onPropertySelect={onSelectProperty ?? (() => {})}
							onValueChanged={onValueChanged}
							getData={getData}
						/>
					))
				) : (
					<tr>
						<td>{t('noProperties', 'No Properties mapped to this classification.')}</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default PropertyGrid;
