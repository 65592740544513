import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setToast } from "../context/appReducer";
import { Severity } from "../models/models";
import { useTranslate } from "@tolgee/react";
import { ComponentsService } from "../services/warehouse";

/**
 * Custom hook for fetching and managing components data.
 * @returns An object containing the components data, loading state, and error state.
 */
const useComponents = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const {data: components, isLoading, isError, refetch} = useQuery({
        queryKey: ['components'],
        queryFn: ComponentsService.getComponents,
    });

    useEffect(() => {
        if (isError) {
            dispatch(setToast({
                message: t('errorLoadingComponents', "Error loading components. Please try again later."),
                severity: Severity.alert, 
                show: true,
            }));
        }
    }, [isError])

    const search = (searchValue: string) => {
        return components?.filter((component) => component.name?.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return {
        components: components ?? [],
        search,
        isLoading,
        isError,
        refetch
    }
}

export default useComponents;