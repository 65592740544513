import StatusDisplay from '../../components/StatusDisplay';
import FiberViewer from './components/FiberViewer';
import ViewerToolbar from './components/ViewerToolbar';
import useGltf from './hooks/useGltf';

type ViewerProps = {
	compositionId: string;
	isBuild?: boolean;
	className?: string;
};

const Viewer = ({ compositionId, isBuild = false, className = '' }: ViewerProps) => {
	var { name, gltf, variantOptions, updateSelectableOption, isLoading } = useGltf(compositionId, isBuild);

	return (
		<div className={'h-100 bg-color-contrast relative' + className}>
			<StatusDisplay isLoading={isLoading} isAbsolute isError={gltf === null}>
				<FiberViewer gltf={gltf} />
			</StatusDisplay>

			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					padding: '10px',
				}}
			>
				<ViewerToolbar
					compositionId={compositionId}
					isLoading={isLoading}
					compositionName={name}
					isLite={isBuild}
					variantOptions={variantOptions}
					updateVariantOptions={updateSelectableOption}
				/>
			</div>
		</div>
	);
};

export default Viewer;
