import { useMutation, useMutationState } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { showConfirmationDialog } from '../../../context/appReducer';
import { setCompositionPath, setIsModified } from '../../../context/editorReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { CompositionBuilderService, InitCompositionBuildDto, Status } from '../../../services/composer';

/**
 * Custom hook for managing the working area.
 *
 * @returns An object containing functions and state related to the working area.
 */
const useEditor = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const compositionPath = useAppSelector<InitCompositionBuildDto[]>((state) => state.editor.compositionPath);
	const isModified = useAppSelector<boolean>((state) => state.editor.isModified);

	/**
	 * Mutation to get the diagram document and init the composition build.
	 */
	const { mutate: initCompositionBuild } = useMutation({
		mutationKey: ['initCompositionBuild'],
		mutationFn: CompositionBuilderService.initCompositionBuild,
		onSuccess: (data, variables, context) => {
			dispatch(setCompositionPath([...compositionPath, data]));
		},
	});

	/**
	 * Mutation state of pending getDiagramDocument mutation.
	 */
	const mutateState = useMutationState({ filters: { mutationKey: ['initCompositionBuild'], status: 'pending' } });

	/**
	 * Resets the composition path and loads the given composition.
	 */
	const resetPath = (compositionId?: string) => {
		dispatch(setCompositionPath([]));

		if (compositionId) initCompositionBuild(compositionId);
	};

	/**
	 * Navigates to the given composition.
	 * If the composition is already in the path, it will navigate to it.
	 * Otherwise, it will fetch the composition and add it to the path.
	 *
	 * @param compositionId The composition id to navigate to.
	 * @param force Whether to force the navigation, even if there are unsaved changes.
	 */
	const navigateToComposition = (compositionId: string, force: boolean = false) => {
		if (!force && isModified) {
			dispatch(
				showConfirmationDialog({
					title: t('unsavedChanges'),
					message: t('wantToClose'),
					handleConfirm: () => {
						dispatch(setIsModified(false));
						navigateToComposition(compositionId, true);
					},
					closeLabel: t('no'),
					confirmLabel: t('yes'),
				}),
			);
			return;
		}
		var index = compositionPath.findIndex((composition) => composition.id === compositionId);
		if (index === -1) {
			initCompositionBuild(compositionId);
		} else {
			dispatch(setCompositionPath(compositionPath.slice(0, index + 1)));
		}
	};

	return {
		currentComposition: compositionPath[compositionPath.length - 1],
		isModified,
		isReadonly: compositionPath.length !== 1 || compositionPath[compositionPath.length - 1].status !== Status.Draft,
		compositionPath,
		navigateToComposition,
		resetPath,
		isLoading: mutateState.length != 0,
	};
};

export default useEditor;
