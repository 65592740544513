import { Divider, Skeleton, Stack } from '@mui/material';
import VariantEditorHeader from '../../components/VariantEditorHeader/VariantEditorHeader';
import useVariantEditor from '../../hooks/useVariantEditor';
import Combination from './components/Combination/Combination';
import { useTranslate } from '@tolgee/react';
import { useAppSelector } from '../../../../../../context/hooks';
import { RootState } from '../../../../../../context/store';

// Styles
import './CombinationsList.scss';

type Props = {
	isLoading: boolean;
	className?: string;
	style?: React.CSSProperties;
};

const CombinationsList = ({ isLoading, className, style }: Props) => {
	const { t } = useTranslate();
	const activeComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const { compositionElements, addCombination } = useVariantEditor();

	return (
		<div className={className + " flex flex-col"} style={{ ...style }}>
			<VariantEditorHeader
				title='Combinations'
				buttonLabel='Add'
				onCreate={() => addCombination(activeComposition?.id!)}
			/>
			<div className='combinationsListBody'>
				{isLoading ? (
					<div className='justify-content-horizontal-space-between full-width'>
						<Skeleton variant='rectangular' width='78%' height='80px' />
						<Skeleton variant='rectangular' width='6%' height='80px' />
						<Skeleton variant='rectangular' width='6%' height='80px' />
						<Skeleton variant='rectangular' width='6%' height='80px' />
					</div>
				) : (
					<Stack
						spacing={1.5}
						width='100%'
						height='100%'
						style={{ overflowY: 'auto' }}
						divider={<Divider orientation='horizontal' flexItem color='#c0c0c0' />}
					>
						{(compositionElements?.combinations?.length ?? 0) > 0 ? (
							compositionElements?.combinations?.map((combination) => (
								<Combination key={combination.id} combination={combination} />
							))
						) : (
							<div>{t('noCombinationsCreated')}</div>
						)}
					</Stack>
				)}
			</div>
		</div>
	);
};

export default CombinationsList;
