// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementsListBody {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  border-right: 2px solid #e0e0e0;
  align-items: center;
  max-height: 63.5vh;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/features/Editor/features/variantEditor/features/ElementsList/ElementsList.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,+BAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".elementsListBody {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    padding: 20px;\n    border-right: 2px solid #e0e0e0;\n    align-items: center;\n    max-height: 63.5vh;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
