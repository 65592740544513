// ASSETS
import liebherrLogoWhite from '../../../assets/images/Liebherr-Logo-white.png';
// STYLE
import './Navigation.scss';
// TRANSLATION
import { T } from '@tolgee/react';

const BottomNavbar = () => {
	return (
		<div className='footer'>
			<img src={liebherrLogoWhite} className="h-6"/>
			<div className='footer-links'>
				<div
					onClick={() => {
						/*LINK TO IMPRESSUM*/
					}}
				>
					<T keyName='impressum'>Impressum</T>
				</div>
				<div
					onClick={() => {
						/*LINK TO PRIVACY*/
					}}
				>
					<T keyName='privacy'>Privacy</T>
				</div>
				<div
					onClick={() => {
						/*LINK TO TERMSOFUSE*/
					}}
				>
					<T keyName='termsOfUse'>Terms of Service</T>
				</div>
			</div>
			<div style={{color: "white"}}>Copyright © Liebherr 2024</div>
		</div>
	);
};
export default BottomNavbar;
