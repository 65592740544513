import { Node } from 'rete-react-render-plugin';
import WorkingAreaNode from '../../features/ReteEditor/components/WorkingAreaNode';

export class ElementInstanceNode extends Node {
    constructor() {
        super();
        this.state = {
            ...this.state,
            isSelected: false,
        };
    }

    render() {
        const { node, editor, bindSocket } = this.props;
		const { outputs, inputs, isSelected } = this.state;

        const elementInstance = node.data.elementInstance;

        return <WorkingAreaNode
            node={node}
            editor={editor}
            bindSocket={bindSocket}
            isSelected={isSelected}
            outputs={outputs}
            inputs={inputs}
            elementInstance={elementInstance}
        />
    }
}
