// STYLING
import '../Modal.scss';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@lbc-toolkit/spinner';
import { Text } from '@lbc-toolkit/text';
import { TextArea } from '@lbc-toolkit/textarea';
import { TextInput } from '@lbc-toolkit/textinput';
import { Tooltip } from '@mui/material';
import { T, useTranslate } from '@tolgee/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import useSearchedClassifications from '../../../hooks/UseSearchedClassifications';
import { CompositionSimpleDto } from '../../../services/composer';
import ClassificationTree from '../../dashboard/navigation/treeview/ClassificationTree';

interface Props {
	setModified: Dispatch<SetStateAction<boolean>>;
	editComposition: CompositionSimpleDto;
	setEditComposition: (composition: CompositionSimpleDto) => void;
}
const CreateComposition = (props: Props) => {
	const { t } = useTranslate();
	const [classTreeSearchText, setClassTreeSearchText] = useState<string>('');
	const { classifications, loading } = useSearchedClassifications(classTreeSearchText);

	const inputClassification = useAppSelector((state: RootState) => state.compositions.selectedCompositionClass);

	return (
		<>
			<Row>
				<Col md={2}>
					<Text>{t('name')}</Text>
				</Col>
				<Col md={10}>
					<TextInput
						width='100%'
						label={<T keyName='required'>Required</T>}
						value={props.editComposition.name ?? ''}
						placeholder={t('enterName')}
						onChange={(value: SetStateAction<string>) => {
							props.setModified(true);
							props.setEditComposition({
								...props.editComposition,
								name: value.toString(),
							});
						}}
					/>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col md={2}>
					<Text>{t('articleNumber')}</Text>
				</Col>
				<Col md={10}>
					<TextInput
						width='100%'
						value={props.editComposition.articleNumber ?? ''}
						placeholder={t('enterArticleNumber')}
						onChange={(value: SetStateAction<string>) => {
							props.setModified(true);
							props.setEditComposition({
								...props.editComposition,
								articleNumber: value.toString(),
							});
						}}
					/>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col md={2}>
					<Text>{t('description')}</Text>
				</Col>
				<Col md={10}>
					<TextArea
						width='100%'
						label={<T keyName='required'>Required</T>}
						value={props.editComposition.description ?? ''}
						placeholder={t('enterDescription')}
						onChange={(value: string) => {
							props.setModified(true);
							props.setEditComposition({
								...props.editComposition,
								description: value,
							});
						}}
					/>
					<div
						className={
							(props.editComposition.description?.length ?? 0 > 255)
								? 'description-length-tooLong'
								: 'description-length'
						}
					>
						<Text>
							{props.editComposition.description &&
								(props.editComposition.description.length > 255
									? t('exceeded') + props.editComposition.description.length.toString() + '/255'
									: props.editComposition.description.length + '/255')}
						</Text>
					</div>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col md={2}>
					<Text>{t('classification')}</Text>
				</Col>
				<Col md={10}>
					<Text>{inputClassification?.name ?? t('selectClassification')}</Text>
					<div className='modal-treeview'>
						<div className='modal-treeview-header'>
							<Text className='modal-treeview-header-title' type='h6'>
								{t('classification')}
							</Text>
							<InputGroup className='modal-treeview-header-inputgroup'>
								<Tooltip
									title={classTreeSearchText.trim().length < 3 ? t('atLeast3Characters') : ''}
									placement='bottom-start'
								>
									<Form.Control
										className='modal-treeview-header-searchBar'
										placeholder={t('search')}
										onChange={(e) => setClassTreeSearchText(e.target.value)}
									/>
								</Tooltip>
								<Button className='modal-searchbar-button'>
									{loading ? (
										<Spinner spin={true} style={{ color: 'black' }} />
									) : (
										<FontAwesomeIcon
											className='fa-xs'
											id='modalSearchIconBtn'
											color='black'
											icon={faSearch}
										/>
									)}
								</Button>
							</InputGroup>
						</div>
						<div className='modal-treeview-container'>
							<ClassificationTree classifications={classifications} />
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default CreateComposition;
