// REACT
import { useEffect, useState } from 'react';
// COMPONENTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@lbc-toolkit/text';
// STYLE
import './Navigation.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight, faGears } from '@fortawesome/pro-solid-svg-icons';
// REDUX
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { setActiveContent, showConfirmationDialog } from '../../../context/appReducer';
// MODELS
import { ContentType } from '../../../models/models';
// TRANSLATION
import { T, useTranslate } from '@tolgee/react';
import { setCompositionPath, setIsModified } from '../../../context/editorReducer';

const SideNavbar = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslate();
	const [expanded, setExpanded] = useState<boolean>(false);
	const activeContent: ContentType = useAppSelector((state: RootState) => state.app.activeContent);
	const unsavedChanges: boolean = useAppSelector((state: RootState) => state.editor.isModified);

	/**
	 * Switches Navbar expansion.
	 */
	useEffect(() => {
		expanded ? expandNavbar() : shrinkNavbar();
	}, [expanded]);

	/**
	 * Handles the navbar shrinking.
	 */
	function shrinkNavbar() {
		const divElement: HTMLElement | null = document.getElementById('sidenavbar');
		if (divElement) {
			divElement.style.width = '64px';
		}
	}

	/**
	 * Handles the navbar expansion.
	 */
	function expandNavbar() {
		const divElement: HTMLElement | null = document.getElementById('sidenavbar');
		if (divElement) {
			divElement.style.width = '200px';
		}
	}

	return (
		<div className='side-navbar' id='sidenavbar'>
			<div>
				<button
					className={
						activeContent === ContentType.compositions
							? 'side-navbar-buttons-active'
							: 'side-navbar-buttons'
					}
					onClick={() => {
						if (unsavedChanges) {
							dispatch(
								showConfirmationDialog({
									title: t('unsavedChanges'),
									message: t('wantToClose'),
									handleConfirm: () => {
										dispatch(setIsModified(false));
										dispatch(setCompositionPath([]))
										dispatch(setActiveContent(ContentType.compositions));
									},
									closeLabel: t('no'),
									confirmLabel: t('yes'),
								}),
							);
						}else {
							dispatch(setCompositionPath([]))
							dispatch(setActiveContent(ContentType.compositions));
						}
					}}
				>
					{expanded ? (
						<Text type='h6'>
							<T keyName='compositions'>Kompositionen</T>
						</Text>
					) : (
						<FontAwesomeIcon style={{ height: '30px' }} icon={faGears as IconProp} swapOpacity />
					)}
				</button>
			</div>
			<div>
				<button
					className='side-navbar-collapse-button'
					onClick={() => {
						setExpanded(!expanded);
					}}
				>
					<FontAwesomeIcon
						className='grow'
						icon={expanded ? (faAngleLeft as IconProp) : (faAngleRight as IconProp)}
						size='lg'
					/>
				</button>
			</div>
		</div>
	);
};
export default SideNavbar;
