import { ClassificationDto } from '../../../../services/catalog';
import ClassificationTreeItems from './ClassificationTreeItems';

type ClassificationTreeProps = {
	classifications: ClassificationDto[];
};

const ClassificationTree = ({ classifications }: ClassificationTreeProps) => {
	return <ClassificationTreeItems classifications={classifications} />;
};

export default ClassificationTree;
