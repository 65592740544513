import {
	FormControl,
	Input,
	InputLabel,
	Menu,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	styled,
} from '@mui/material';
import { Button } from '@lbc-toolkit/button';
import { faCheck, faMinus, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import '../../CombinationsList.scss';
import { useState } from 'react';
import useVariantEditor from '../../../../hooks/useVariantEditor';
import useCombination from './hooks/useCombination';
import { CombinationDto } from '../../../../../../../../services/composer';
import { useAppDispatch, useAppSelector } from '../../../../../../../../context/hooks';
import { RootState } from '../../../../../../../../context/store';
import { Severity } from '../../../../../../../../models/models';
import { setToast, showToast } from '../../../../../../../../context/appReducer';

type Props = {
	combination: CombinationDto;
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f0f0f0',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Combination = ({ combination }: Props) => {
	const dispatch = useAppDispatch();
	const activeComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const {
		compositionElements,
		changeCombinationName,
		removeCombination,
		addCombinationEntry,
		removeCombinationEntry,
	} = useVariantEditor();

	const {
		name,
		onNameChange,
		max,
		min,
		minMaxHint,
		selectedElement,
		onMinChange,
		onMaxChange,
		onElementSelect,
		validateInput,
		onReset,
	} = useCombination(combination);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const onOpenAddCombinationEntryDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const onCloseAddCombinationEntryDialog = () => {
		onReset();
		setAnchorEl(null);
	};

	return (
		<>
			<div className='combinationEntry'>
				<div
					className='justify-content-horizontal-start'
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
				>
					<FormControl variant='standard'>
						<InputLabel htmlFor='combinationName'>Name</InputLabel>

						<Input
							id='combinationName'
							placeholder='Enter name'
							style={{ paddingTop: '7px', marginBottom: '10px' }}
							type='text'
							value={name}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => onNameChange(event.target.value)}
							onBlur={() => changeCombinationName(combination.id!, name)}
						/>
					</FormControl>
					<Stack
						direction='row'
						spacing={1}
						alignItems='center'
						flexWrap='wrap'
						gap={1}
						style={{ alignItems: 'flex-start' }}
					>
						{combination.entries?.map((combinationEntry, index) => (
							<Item key={index} style={{ marginLeft: '0', marginRight: '8px', marginBottom: '4px' }}>
								{combinationEntry.min}
								{combinationEntry.max !== null && '-' + combinationEntry.max}x{' '}
								{
									compositionElements?.contains?.find(
										(element) => element.id === combinationEntry.elementInstanceId,
									)?.name
								}
							</Item>
						))}
					</Stack>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
					<Button
						type='secondary'
						icon={faMinus}
						onClick={() =>
							removeCombinationEntry({
								compositionId: activeComposition?.id!,
								combinationId: combination.id!,
							})
						}
					/>
					<Button
						type='secondary'
						icon={faPlus}
						onClick={onOpenAddCombinationEntryDialog}
						aria-controls={open ? 'add-combination-entry' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}
					/>
					<Button
						type='secondary'
						icon={faTrash}
						onClick={() =>
							removeCombination({ compositionId: activeComposition?.id!, combinationId: combination.id! })
						}
					/>
				</div>
			</div>
			<Menu
				anchorEl={anchorEl}
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={onCloseAddCombinationEntryDialog}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<div className='justify-content-horizontal-space-between p-2' style={{ width: '500px' }}>
					<TextField
						label='Min'
						value={min}
						onChange={(event) => onMinChange(parseInt(event.target.value))}
						onBlur={(event) => {
							if (event.target.value === "") {
								onMinChange(0);
							}
						}}
						variant='standard'
						type='number'
						style={{ width: '75px' }}
						InputLabelProps={{ shrink: true }}
					/>
					<TextField
						label='Max'
						value={max ?? ''}
						onChange={(event) => onMaxChange(parseInt(event.target.value))}
						onBlur={(event) => {
							if (event.target.value === "") {
								onMaxChange(0);
							}
						}}
						variant='standard'
						type='number'
						style={{ width: '75px' }}
						InputLabelProps={{ shrink: true }}
					/>

					<FormControl variant='standard'>
						<InputLabel id='element'>Element</InputLabel>
						<Select
							variant='standard'
							style={{ width: '250px' }}
							value={selectedElement?.id ?? ''}
							onChange={(e) => onElementSelect(e.target.value)}
						>
							{compositionElements?.contains?.map((element) => (
								<MenuItem key={element.id} value={element.id ?? ''}>
									{element.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button
						icon={faCheck}
						onClick={() => {
							if (validateInput()) {
								addCombinationEntry({
									compositionId: activeComposition?.id!,
									combinationId: combination.id!,
									elementInstance: selectedElement!,
									min: min,
									max: max,
								});
								onCloseAddCombinationEntryDialog();
							} else {
								dispatch(showToast({ message: 'Invalid input', severity: Severity.alert }));
							}
						}}
					/>
				</div>
				<div className='pl-2' style={{ color:'red' }} >{minMaxHint}</div>
			</Menu>
		</>
	);
};

export default Combination;
