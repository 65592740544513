import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ModelsService, VariantOptions } from '../../../services/modelmanager';
const useGltf = (compositionId: string, isBuild: boolean = false) => {
	const [variantOptions, setVariantOptions] = useState<VariantOptions[]>([]);
	const [name, setName] = useState<string>('');

	const {
		data: gltf,
		isLoading,
		isError: fetchError,
		refetch,
	} = useQuery({
		queryKey: ['getGltf', compositionId, variantOptions],
		queryFn: async () => {
			const gltfDto = await ModelsService.getGltf(compositionId, isBuild, variantOptions);
			if (!gltfDto?.data) return;
			if (gltfDto.variantOptions) {
				setVariantOptions(gltfDto.variantOptions);
				setName(gltfDto.compositionName ?? '');
			}
			const bytes = Uint8Array.from(window.atob(gltfDto.data), (c) => c.charCodeAt(0));
			const parsedGltf = await new GLTFLoader().parseAsync(bytes.buffer, '/');
			return parsedGltf;
		},
	});

	const updateSelectableOption = (newOptions: VariantOptions[]) => {
		setVariantOptions(newOptions);
	};

	return {
		name,
		gltf,
		isLoading,
		variantOptions,
		setVariantOptions,
		updateSelectableOption,
		refetch,
		isError: fetchError,
	};
};

export default useGltf;
