// REACT
import { useEffect, useState } from 'react';
// STYLING
import './Treeview.scss';
// COMPONENTS
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faCircleNodes,
	faFolder,
	faGear,
	faGears,
	faPlug,
	faSquareChevronDown,
	faSquareChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@lbc-toolkit/spinner';
import { IconButton, Tooltip } from '@mui/material';
// REDUX
import { useAppDispatch, useAppSelector } from '../../../../context/hooks';
import { RootState } from '../../../../context/store';
// API
import { ClassificationDto, ClassificationsService, UsageType } from '../../../../services/catalog';
// TRANSLATION
import { useTranslate } from '@tolgee/react';
import { setSelectedCompositionClass } from '../../../../context/compositionsReducer';
import ClassificationTreeItems from './ClassificationTreeItems';

type ClassificationTreeNodeProps = {
	classification: ClassificationDto;
};

const ClassificationTreeNode = ({ classification }: ClassificationTreeNodeProps) => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const currentClass = useAppSelector((state: RootState) => state.compositions.selectedCompositionClass);
	const [expanded, setExpanded] = useState<boolean>(false);
	const [children, setChildren] = useState<ClassificationDto[]>(classification.children ?? []);
	const [childrenLoading, setChildrenLoading] = useState<boolean>(false);
	const [hasChildren, setHasChildren] = useState<boolean>(false);

	useEffect(() => {
		setChildren(classification.children ?? []);
	}, [classification.children]);

	useEffect(() => {
		setExpanded(children.length !== 0);
	}, [children]);

	useEffect(() => {
		if (classification.id) {
			ClassificationsService.getChildren(classification.id)
				.then((children: ClassificationDto[]) => {
					setChildren(children);
					setHasChildren(children.length > 0);
				})
				.catch(() => {
					setHasChildren(false);
				});
		}
	}, [classification.id]);

	useEffect(() => {
		if (expanded && classification.children?.length === 0) {
			setChildrenLoading(true);
			// TODO: Localization
			ClassificationsService.getChildren(classification.id!)
				.then((children: ClassificationDto[]) => {
					setChildren(children);
				})
				.finally(() => {
					setChildrenLoading(false);
				});
		}
	}, [expanded]);

	/**
	 * Renders the indicator icon to show if the selected class is a collector, connector or component
	 */
	const renderIndicatorIcon = () => {
		if (classification.usageType === UsageType.Collector) {
			return (
				<Tooltip title={t('collectorClass')} arrow>
					<div>
						<FontAwesomeIcon
							className='treeview-listitem-indicator'
							color='black'
							icon={faFolder as IconProp}
						/>
					</div>
				</Tooltip>
			);
		} else if (classification.usageType === UsageType.Connector) {
			return (
				<Tooltip title={t('connector')} arrow>
					<div>
						<FontAwesomeIcon
							className='treeview-listitem-indicator'
							color='black'
							icon={faPlug as IconProp}
						/>
					</div>
				</Tooltip>
			);
		} else if (classification.usageType === UsageType.Composition) {
			return (
				<Tooltip title={t('composition')} arrow>
					<div>
						<FontAwesomeIcon
							className='treeview-listitem-indicator'
							color='black'
							icon={faGears as IconProp}
						/>
					</div>
				</Tooltip>
			);
		} else if (classification.usageType === UsageType.CompositionVariant) {
			return (
				<Tooltip title={t('compositionvariant')} arrow>
					<div>
						<FontAwesomeIcon
							className='treeview-listitem-indicator'
							color='black'
							icon={faCircleNodes as IconProp}
						/>
					</div>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip title={t('component')} arrow>
					<div>
						<FontAwesomeIcon
							className='treeview-listitem-indicator'
							color='black'
							icon={faGear as IconProp}
						/>
					</div>
				</Tooltip>
			);
		}
	};

	/**
	 * Handles the on click of one row in the treeview
	 */
	const OnClickTreeItem = () => {
		if (
			classification.usageType === UsageType.Composition ||
			classification.usageType === UsageType.CompositionVariant
		)
			dispatch(setSelectedCompositionClass(classification));
	};

	return (
		<li className='treeview-listitem'>
			<div
				className={currentClass?.id === classification.id ? 'treeview-item-active' : 'treeview-item'}
				onClick={OnClickTreeItem}
				style={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					{hasChildren && (
						<div
							onClick={() => {
								setExpanded(!expanded);
							}}
						>
							<IconButton>
								<FontAwesomeIcon
									className='fa-xs'
									color={expanded ? '#ffd000' : '#000000'}
									icon={
										expanded
											? (faSquareChevronDown as IconProp)
											: (faSquareChevronRight as IconProp)
									}
								/>
							</IconButton>
						</div>
					)}
					<div className='treeview-listitem-indicator-container'>{renderIndicatorIcon()}</div>
					<div className='treeview-listitem-name'>{classification.name}</div>
				</div>
			</div>
			{childrenLoading && (
				<div className='table-spinner'>
					<Spinner spin={true} />
				</div>
			)}
			{expanded && <ClassificationTreeItems classifications={children} />}
		</li>
	);
};

export default ClassificationTreeNode;
