
type DashboardLayoutProps = {
    header: React.ReactNode;
    sidebar: React.ReactNode;
    content: React.ReactNode;
    footer: React.ReactNode;
};

const DashboardLayout = ({
    header,
    sidebar,
    content,
    footer
}: DashboardLayoutProps) => {

    return (
        <div style={{
            width: "100vw", 
            height: "100vh", 
            display: "flex", 
            flexDirection: "column",
            overflow: "hidden"
        }}
        >
            {header}
            
            <div style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "row"
            }}>
                {sidebar}

                <div style={{ flex: 1, backgroundColor:"#eff3f6" }}>
                    {content}
                </div>
            </div>
            
            {footer}
        </div>
    );

}

export default DashboardLayout;