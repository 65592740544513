import { useState } from 'react';
import ToolbarButton from '../ToolbarButton';
import { faGears } from '@fortawesome/pro-solid-svg-icons';
import { useTranslate } from '@tolgee/react';
import { EditorProps } from '../../../../WorkingArea';
import AddInstanceModal from '../../../../../../components/AddInstanceModal';
import useCompositions from '../../../../../../../../hooks/useCompositions';

const AddCompositionInstanceToolbarButton = ({ editor }: EditorProps) => {
	const { t } = useTranslate();
	const [show, setShow] = useState<boolean>(false);

	const { compositions, isLoading } = useCompositions();

	return (
		<>
			<AddInstanceModal
				label={t('addCompositionInstance', 'Add composition instance')}
				elementBases={compositions}
				isLoading={isLoading}
				show={show}
				onClose={() => setShow(false)}
				onSave={(data) => editor?.addElementInstance(data)}
			/>
			<ToolbarButton
				tooltip={t('addCompositionInstance', 'Add Composition Instance')}
				icon={faGears}
				onClick={() => setShow(true)}
			/>
		</>
	);
};

export default AddCompositionInstanceToolbarButton;
