
type DividerProps = {
    orientation?: 'horizontal' | 'vertical';
    thickness?: number | string;
    space?: number | string;
    className?: string;
    style?: React.CSSProperties;
}

export const Divider: React.FC<DividerProps> = ({ orientation = "horizontal", thickness = 2, space = "0px", className, style }) => {
    return <div className={className} style={{
        position: "relative",
        backgroundColor: "black",
        width: orientation === 'vertical' ? thickness : '100%',
        height: orientation === 'horizontal' ? thickness : '100%',
        margin: orientation === 'vertical' ? `0 ${space}` : `${space} 0`,
        flexShrink: 0,
        ...style
    }}/>;
}