import { Tooltip } from '@mui/material';
import { PropertyDiscriminator, PropertyDto } from '../../../../services/catalog';
import { PropertyEntryDto, PropertyUsageType } from '../../../../services/warehouse';
import './PropertyGridProperty.scss';
import SimpleProperty from './propertyType/SimpleProperty';
import { useEffect, useState } from 'react';

export type PropertyGridPropertyProps = {
	editMode: boolean;
	selected: boolean;
	property: PropertyDto;
	propertyEntry: PropertyEntryDto | undefined;
	selectedPropertyType: PropertyUsageType;
	onChange: (value: PropertyEntryDto) => void;
	onClick: () => void;
};

const PropertyGridProperty = (props: PropertyGridPropertyProps) => {
	const { selected, property, propertyEntry, onClick } = props;

	const [isCorrectType] = useState<boolean>(propertyEntry?.propertyUsageType === props.selectedPropertyType)
	const [isPropertyTypeInstance] = useState<boolean>(propertyEntry?.propertyUsageType === PropertyUsageType.Instance);

	if(PropertyDiscriminator && property.propertyDiscriminator === PropertyDiscriminator.Undefined){
		return <></>;
	}
	/**
	 * render inputField or value as string depending on editMode
	 * which inputType is shown depends on property's datatype
	 */
	const renderValueField = () => {
		switch (props.property.propertyDiscriminator){
			case PropertyDiscriminator.Table:
				return <></>;
			case PropertyDiscriminator.Simple:
				return SimpleProperty({...props});
			default:
				return <></>;
		}
	};

	/**
	 * Tooltip shown when hovered over the table row
	 */
	const getInfoTooltipText = () => {
		return (
			<div className='property-tooltip'>
				type: {propertyEntry?.propertyUsageType}
				<br />
				name: {property.name}
			</div>
		);
	};

	return (<Tooltip title={getInfoTooltipText()} placement='left' enterNextDelay={500} enterDelay={500}>
			<tr onClick={onClick} className={`propertygrid-property ${selected ? 'selected' : ''}`}>
				<td className='property-cell-name'>
					<div className='property-name'>{property.name}</div>
					<div className='property-type-indicator'>{isPropertyTypeInstance ? 'I' : 'T'}</div>
				</td>
				<td className={`${isCorrectType ? '' : 'propertygrid-cell-disable'}`}>
					{renderValueField()}
				</td>
			</tr>
		</Tooltip>
	);
	
};

export default PropertyGridProperty;
