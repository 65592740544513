import { Text } from '@lbc-toolkit/text';
import { Button } from '@lbc-toolkit/button';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import './VariantEditorHeader.scss';

interface Props {
    title: string;
    buttonLabel: string;
    onCreate: (event: React.MouseEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
}

const VariantEditorHeader = ({title, buttonLabel, onCreate, style}: Props) => {

    return (
        <div className='variantEditorHeader' style={style}>
            <Text type='h2'>{title}</Text>
            <Button icon={faPlus} label={buttonLabel} onClick={onCreate} />
        </div>
    );

}

export default VariantEditorHeader;