// Services
import {
	ObjectPropertyDto,
	ObjectPropertyValue,
	PropertyDiscriminator,
	PropertyEntryDto,
	SimplePropertyValue,
	TablePropertyValue,
} from '../../../../../services/warehouse';

// Components
import ObjectTableProperty from './ObjectTableProperty';
import SimplePropertyInput from './SimplePropertyInput';

interface Props {
	property: ObjectPropertyDto;
	propertyEntry: PropertyEntryDto;
	onChange: (value: PropertyEntryDto) => void;
	editMode: boolean;
}

const ObjectPropertyGrid = ({ property, propertyEntry, onChange, editMode }: Props) => {
	const onUpdate = (id: string, value: any) => {
		const stringValue = JSON.stringify(value);
		const objectValue = propertyEntry.value as ObjectPropertyValue;
		let updatedValues: Record<string, SimplePropertyValue>;
		if (!objectValue || !objectValue.values || Object.keys(objectValue.values).length === 0) {
			updatedValues = {
				[id + property.id]: { discriminator: 'SimplePropertyValue', value: stringValue } as SimplePropertyValue,
			};
		} else {
			updatedValues = {
				...objectValue.values,
				[id + property.id]: { discriminator: 'SimplePropertyValue', value: stringValue } as SimplePropertyValue,
			};
		}
		const newValue: ObjectPropertyValue = {
			...objectValue,
			values: updatedValues,
		};
		const newPropertyEntry = {
			...propertyEntry,
			value: newValue,
		};
		onChange(newPropertyEntry);
	};

	const getSafeValue = (id: string): string => {
		const value = (propertyEntry.value as ObjectPropertyValue)?.values?.[id + property.id] as
			| SimplePropertyValue
			| undefined;
		return value?.value ? JSON.parse(value.value) : '';
	};

	return (
		<div>
			<p>{property.name}</p>
			{property.properties?.map((tempProp, index) => {
				if (!tempProp.property) {
					return;
				}

				switch (tempProp.property.propertyDiscriminator) {
					case PropertyDiscriminator.Table:
						return (
							<div key={index}>
								<ObjectTableProperty
									property={tempProp.property}
									propertyEntry={propertyEntry!}
									onChange={onChange}
									editMode={editMode}
								/>
							</div>
						);
					case PropertyDiscriminator.Simple:
						return (
							<div key={index}>
								<SimplePropertyInput
									property={tempProp.property}
									initValue={getSafeValue(tempProp.property.id!)}
									editMode={editMode}
									onChange={onUpdate}
								/>
							</div>
						);
					case PropertyDiscriminator.Object:
						return (
							<div key={index}>
								<ObjectPropertyGrid
									propertyEntry={propertyEntry}
									editMode={editMode}
									onChange={onChange}
									property={tempProp.property as ObjectPropertyDto}
								/>
							</div>
						);
				}
			})}
		</div>
	);
};
export default ObjectPropertyGrid;
