import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LeaveConfirmationDialog from '../components/modal/ConfirmationDialog/LeaveConfirmationDialog';
import { ContentType, ActionModeType, Severity, ToastBar, ConfirmationDialogProps } from '../models/models';

export interface AppReducerState {
	user: null;
	token: string;
	language: string;
	toast: ToastBar;
	confirmation: ConfirmationDialogProps;
	activeContent: ContentType;
	actionMode: ActionModeType;
}

export const initialState: AppReducerState = {
	user: null,
	token: '',
	language: 'EN',
	toast: {
		show: false,
		message: '',
		severity: Severity.default,
	},
	confirmation: { show: false },
	activeContent: ContentType.compositions,
	actionMode: ActionModeType.None,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<null>) => {
			state.user = action.payload;
		},
		setToast: (state, action: PayloadAction<ToastBar>) => {
			state.toast = action.payload;
		},
		showToast: (state, action: PayloadAction<{message:string, severity?: Severity}>) =>{
			state.toast = {show: true, message: action.payload.message, severity: action.payload.severity ?? Severity.alert};
		},
		setLanguage: (state, action: PayloadAction<string>) => {
			state.language = action.payload;
		},
		setActiveContent: (state, action: PayloadAction<ContentType>) => {
			state.activeContent = action.payload;
		},
		setActionMode: (state, action: PayloadAction<ActionModeType>) => {
			state.actionMode = action.payload;
		},
		showConfirmationDialog: (state, action: PayloadAction<ConfirmationDialogProps>) => {
			state.confirmation = { ...action.payload, show: true };
		},
		hideConfirmationDialog: (state) => {
			state.confirmation = { show: false };
		},
	},
});

export const {
	setUser,
	setToast,
	showToast,
	setLanguage,
	setActiveContent,
	setActionMode,
	showConfirmationDialog,
	hideConfirmationDialog,
} = appSlice.actions;

export default appSlice.reducer;
