import DeleteModal from "../../components/modal/DeleteModal/DeleteModal";
import { setActionMode } from "../../context/appReducer";
import { setActiveComposition } from "../../context/compositionsReducer";
import { useAppDispatch, useAppSelector } from "../../context/hooks";
import { RootState } from "../../context/store";
import { ActionModeType } from "../../models/models";
import { CompositionSimpleDto } from "../../services/composer";
import { useDeleteComposition } from "./hooks/useDeleteComposition";

interface DeleteCompositionProps {
	selectedComposition: CompositionSimpleDto;
}



const DeleteComposition: React.FC<DeleteCompositionProps> = ({ selectedComposition }) => {
	const actionMode = useAppSelector((state: RootState) => state.app.actionMode);

	const dispatch = useAppDispatch();
	const { deleteComposition, dependencies, isLoading } = useDeleteComposition(selectedComposition);

	const handleClose = () => {
		
        dispatch(setActionMode(ActionModeType.None));
        dispatch(setActiveComposition(null));
	};

	const handleDelete = () => {
		if (selectedComposition) {
			deleteComposition();
		}
		handleClose();
	};

	return (
		<DeleteModal
			deleteMode={actionMode === ActionModeType.Delete}
			itemName={selectedComposition?.name!}
			onClose={handleClose}
			onDelete={handleDelete}
			dependencies={dependencies ?? null}
			isLoading={isLoading}
		/>
	);
};

export default DeleteComposition;
