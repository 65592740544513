import Viewer from "../../Viewer";

type SidebarViewerProps = {
	compositionId: string;
	viewerSize: number;
};

const ResizeableViewer = ({ viewerSize, compositionId }: SidebarViewerProps) => {
	return (
		<div
			className={viewerSize === 0 ? 'w-[0%]' : viewerSize === 1 ? 'w-[30%]' : 'w-[60%]'}
			style={{ transition: 'all 0.5s ease-in-out' }}
		>
			{Boolean(viewerSize) && <Viewer compositionId={compositionId} isBuild />}
		</div>
	);
};

export { ResizeableViewer };

