import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassificationDto } from '../services/catalog';
import { CompositionSimpleDto } from '../services/composer';

export interface CompositionsReducerState {
	compositionList: CompositionSimpleDto[];
	activeComposition: CompositionSimpleDto | null;
	createdComposition: CompositionSimpleDto | null;
	compositionListNeedsReload: boolean;
	selectedCompositionClass: ClassificationDto | null;
}

export const initialState: CompositionsReducerState = {
	compositionList: [],
	activeComposition: null,
	createdComposition: null,
	compositionListNeedsReload: false,
	selectedCompositionClass: null,
};

export const appSlice = createSlice({
	name: 'compositions',
	initialState,
	reducers: {
		setCompositionList: (state, action: PayloadAction<CompositionSimpleDto[]>) => {
			state.compositionList = action.payload;
		},
		setActiveComposition: (state, action: PayloadAction<CompositionSimpleDto | null>) => {
			state.activeComposition = action.payload;
		},
		setCreatedComposition: (state, action: PayloadAction<CompositionSimpleDto | null>) => {
			state.createdComposition = action.payload;
		},
		setCompositionsListNeedsReload: (state, action: PayloadAction<boolean>) => {
			state.compositionListNeedsReload = action.payload;
		},
		setSelectedCompositionClass: (state, action: PayloadAction<ClassificationDto | null>) => {
			state.selectedCompositionClass = action.payload;
		},
	},
});

export const {
	setCompositionList,
	setActiveComposition,
	setCreatedComposition,
	setCompositionsListNeedsReload,
	setSelectedCompositionClass,
} = appSlice.actions;

export default appSlice.reducer;
