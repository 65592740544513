import React, { useState } from 'react';
import { Button } from '@lbc-toolkit/button';
import { TextInput } from '@lbc-toolkit/textinput';
import { Alert, Col, Container, Modal, Row } from 'react-bootstrap';
import { T, useTranslate } from '@tolgee/react';
import { Spinner } from '@lbc-toolkit/spinner';
import '../Modal.scss';

interface DeleteModalProps {
	deleteMode: boolean;
	itemName: string;
	onClose: () => void;
	onDelete: () => void;
	dependencies: string[] | null;
	isLoading: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
	deleteMode,
	itemName,
	onClose,
	onDelete,
	dependencies,
	isLoading,
}) => {
	const { t } = useTranslate();
	const [deleteConfirmed, setDeleteConfirmed] = useState(false);
	const isDependencies = dependencies && dependencies.length > 0;

	return (
		<Modal size='lg' centered show={deleteMode} style={{ borderRadius: 0, border: '1px solid black' }}>
			<Modal.Header style={{ justifyContent: 'center' }}>
				<Modal.Title>{t('confirmDelete')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					{isLoading ? (
						<Row>
							<Col sm={3}>
								<Spinner spin={true} />
							</Col>
						</Row>
					) : (
						<>
							{!isDependencies && (
								<Row style={{ paddingTop: '1.5vh' }}>
									<Col sm={12}>
										<TextInput
											className='row-inputField'
											placeholder={itemName}
											label={t('confirmDeleteLabel')}
											onChange={(value: string) => {
												setDeleteConfirmed(value === itemName);
											}}
										/>
									</Col>
								</Row>
							)}
							{isDependencies && (
								<Row>
									<Col sm={12}>
										<Alert variant='warning' style={{ fontSize: '0.9rem' }}>
											<T
												keyName='deleteWarning'
												params={{
													strong: <strong />,
													dependencies: dependencies.join(', '),
													itemName: itemName,
													br: <br />,
												}}
											/>
										</Alert>
									</Col>
								</Row>
							)}
						</>
					)}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant='primary' onClick={onClose} label={t('cancelBtn')} />
				<Button
					variant='primary'
					onClick={onDelete}
					disabled={!deleteConfirmed || (isDependencies && !isLoading)}
					label={t('confirmBtn')}
				/>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteModal;
