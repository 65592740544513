// REACT
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
// STYLING
import './DetailsModal.scss';
// MODELS
// COMPONENTS
import { Text } from '@lbc-toolkit/text';
import { TextInput } from '@lbc-toolkit/textinput';
import { TextArea } from '@lbc-toolkit/textarea';
import { Dropdown } from '@lbc-toolkit/dropdown';
import { Col, Row } from 'react-bootstrap';
// TRANSLATIONS
import { useTranslate } from '@tolgee/react';
import { ClassificationDto, ClassificationsService } from '../../../services/catalog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCheck, faPen } from '@fortawesome/pro-regular-svg-icons';
import { ActionModeType, Severity } from '../../../models/models';
import { CompositionSimpleDto, CompositionsService, Status, UpdateCompositionDto } from '../../../services/composer';
import { setActiveComposition, setCompositionsListNeedsReload } from '../../../context/compositionsReducer';
import { setToast } from '../../../context/appReducer';
import { queryClient } from '../../App';

const DetailsGeneral = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslate();
	const activeComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const [compClassification, setClassification] = useState<ClassificationDto>();
	const [actionMode, setActionMode] = useState<ActionModeType>(ActionModeType.Details);
	const [editObject, setEditObject] = useState<CompositionSimpleDto | null>(activeComposition);

	/**
	 * Called after initial render.
	 * Get classification and stores in state
	 */
	useEffect(() => {
		ClassificationsService.getClassification(activeComposition?.classificationId!).then(
			(classification: ClassificationDto) => {
				setClassification(classification);
			},
		);
	}, []);

	useEffect(() => {
		if (actionMode === ActionModeType.Edit) {
			setEditObject(activeComposition);
		}
	}, [actionMode]);

	/**
	 * Starts edit mode
	 */
	const onEdit = () => {
		setActionMode(ActionModeType.Edit);
	};

	/**
	 * Cancels edit mode
	 */
	const onCancel = () => {
		setActionMode(ActionModeType.Details);
	};

	/**
	 * Saves changes on editor for general attributes
	 */
	const onCheck = () => {
		dispatch(setActiveComposition(editObject));

		const updateComposition: UpdateCompositionDto = {
			id: editObject?.id!,
			name: editObject?.name!,
			description: editObject?.description!,
			articleNumber: editObject?.articleNumber,
			status: editObject?.status!,
			classificationId: editObject?.classificationId!,
		};

		CompositionsService.updateComposition(updateComposition)
			.then(() => {
				dispatch(setCompositionsListNeedsReload(true));
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('compositionUpdated'),
					}),
				);

				queryClient.invalidateQueries({ queryKey: ['compositions'] });
			})
			.catch((error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('couldNotSaved'),
					}),
				);
			});

		setActionMode(ActionModeType.Details);
	};

	return (
		<div className='details-card'>
			<Row className='px-4 py-2'>
				<Col sm={10} className='px-0'>
					<Text className='details-card-header' type='h3'>
						{t('generalParameters')}
					</Text>
				</Col>
				<Col sm={2} className='px-0'>
					{activeComposition?.status != Status.Deprecated && (
						<div className='general-params-edit'>
							{actionMode === ActionModeType.Edit ? (
								<Row className='px-0'>
									<Col sm={6}>
										<FontAwesomeIcon
											className='clickable px-1'
											icon={faCancel}
											onClick={onCancel}
										/>
									</Col>
									<Col sm={6}>
										<FontAwesomeIcon
											className='clickable modal-card-edit-btn px-1'
											icon={faCheck}
											onClick={onCheck}
										/>
									</Col>
								</Row>
							) : (
								<FontAwesomeIcon
									className='clickable modal-card-edit-btn px-1'
									icon={faPen}
									onClick={onEdit}
								/>
							)}
						</div>
					)}
				</Col>
			</Row>
			<Row className='px-4 py-4'>
				<Col sm={4}>
					<Text>{t('name')}</Text>
				</Col>
				<Col sm={8}>
					{actionMode === ActionModeType.Edit && activeComposition?.status == Status.Draft ? (
						<TextInput
							className='row-inputField'
							value={editObject?.name}
							onChange={(value: string) => {
								setEditObject({
									...editObject,
									name: value,
								} as CompositionSimpleDto);
							}}
						/>
					) : (
						<Text>{activeComposition?.name}</Text>
					)}
				</Col>
			</Row>
			<Row className='px-4 py-4'>
				<Col sm={4}>
					<Text>{t('articleNumber')}</Text>
				</Col>
				<Col sm={8}>
					{actionMode === ActionModeType.Edit && activeComposition?.status == Status.Draft ? (
						<TextInput
							className='row-inputField'
							value={editObject?.articleNumber}
							onChange={(value: string) => {
								setEditObject({
									...editObject,
									articleNumber: value,
								} as CompositionSimpleDto);
							}}
						/>
					) : (
						<Text>{activeComposition?.articleNumber}</Text>
					)}
				</Col>
			</Row>
			<Row className='px-4 py-4'>
				<Col sm={4}>
					<Text>{t('desc')}</Text>
				</Col>
				<Col sm={8}>
					{actionMode === ActionModeType.Edit && activeComposition?.status == Status.Draft ? (
						<TextArea
							className='row-inputField'
							value={editObject?.description}
							onChange={(value: string) => {
								setEditObject({
									...editObject,
									description: value,
								} as CompositionSimpleDto);
							}}
						/>
					) : (
						<Text>{activeComposition?.description}</Text>
					)}
				</Col>
			</Row>

			<Row className='px-4 py-4'>
				<Col sm={4}>
					<Text>{t('classification')}</Text>
				</Col>
				<Col sm={8}>
					<Text>{compClassification?.name}</Text>
				</Col>
			</Row>

			<Row className='px-4 py-4'>
				<Col sm={4}>{t('status')}</Col>
				<Col sm={8}>
					{actionMode === ActionModeType.Edit ? (
						<Dropdown
							width='auto'
							options={Object.keys(Status)
								.map((key) => {
									return { label: Status[key as keyof typeof Status], value: key };
								})
								.filter((option) => {
									if (activeComposition?.status === Status.Draft) {
										return true;
									} else {
										return option.value !== Status.Draft;
									}
								})}
							placeholder={t('status')}
							onSelect={(value: Status) => {
								setEditObject({
									...editObject,
									status: value,
								});
							}}
							selected={editObject?.status}
						/>
					) : (
						activeComposition?.status
					)}
				</Col>
			</Row>
		</div>
	);
};

export default DetailsGeneral;
