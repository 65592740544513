import { faPlug } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@lbc-toolkit/text';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { ConnectorDirection } from '../../../../../../services/composer';
import useEditor from '../../../../hooks/useEditor';
import useRete from '../../hooks/useRete';
import { EditorProps } from '../../WorkingArea';
import useOpenSockets from './hooks/useOpenSockets';

// Styling
import './styles/openSocketsPanel.scss';

type OpenSocketsPanelProps = {} & EditorProps;

export const OpenSocketsPanel = ({ editor }: OpenSocketsPanelProps) => {
	const { currentComposition, isModified } = useEditor();
	const { isImporting, selectedOpenSocket } = useRete(editor);
	const { openSockets, isError, isPending, refetch, onClick, onHover } = useOpenSockets(currentComposition?.id ?? '');

	useEffect(() => {
		if (!editor) return;

		editor?.on(['modified'], () => {
			refetch();
		});
	}, [editor]);

	return (
		<div
			className='open-connector-container absolute'
			style={{
				transform: 'translate(-100%, -100%)',
			}}
		>
			<Text type='h6' className='open-connector-label'>
				Open Connectors ({isPending ? '' : openSockets.length})
			</Text>
			<div className='open-connector-list'>
				{isError ? (
					<div className='open-connector-item error'>Error fetching open connectors</div>
				) : isPending ? (
					<Spinner animation='border' style={{ margin: '8px' }} />
				) : (
					openSockets.map((openSocket, index) => {
						return (
							<div
								key={index}
								className={`open-connector-item ${openSocket === selectedOpenSocket ? 'selected' : ''}`}
								onClick={() => onClick(openSocket)}
								onMouseEnter={() => onHover(openSocket)}
								onMouseLeave={() => onHover()}
							>
								<FontAwesomeIcon
									className={`fa-light px-1 open-connector-socket-${
										openSocket.direction === ConnectorDirection.In ? 'in' : 'out'
									}`}
									size='sm'
									icon={faPlug}
								/>
								{openSocket.connectorName}
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};
