import { Button } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
import { TextInput } from '@lbc-toolkit/textinput';
import { useMutation } from '@tanstack/react-query';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { Modal, ModalProps, Spinner, Table } from 'react-bootstrap';
import { showToast } from '../../../context/appReducer';
import { useAppDispatch } from '../../../context/hooks';
import { Severity } from '../../../models/models';
import { CompositionBuilderService, CompositionSimpleDto, ElementInstanceDto } from '../../../services/composer';
import { ComponentSimpleDto } from '../../../services/warehouse';
import useEditor from '../hooks/useEditor';

type AddInstanceModalProps = {
	label: string;
	elementBases: (ComponentSimpleDto | CompositionSimpleDto)[];
	isLoading: boolean;
	onSave: (data: ElementInstanceDto) => void;
} & ModalProps;

const AddInstanceModal = ({ label, elementBases, isLoading, show, onClose: superOnClose, onSave }: AddInstanceModalProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslate();
	const { currentComposition } = useEditor();
	const [selectedElementBase, setSelectedElementBase] = useState<ComponentSimpleDto | CompositionSimpleDto | null>(null);
	const [searchValue, setSearchValue] = useState<string>('');
	
	const onClose = () => {
		setSelectedElementBase(null);
		superOnClose();
	}

	const { mutate: addInstance, isPending: isAddingInstance } = useMutation({
		mutationFn: (args: {compositionId: string, elementBaseId: string}) => CompositionBuilderService.addInstance(args.compositionId, args.elementBaseId),
		onSuccess: (data) => {
			onSave(data);
			dispatch(
				showToast({
					message: t('instanceAdded', 'Instance added.'),
					severity: Severity.success,
				}),
			);
			onClose();
		},
		onError: (error) => {
			console.error(error);
			dispatch(
				showToast({
					message: t('errorAddingInstance', 'Error adding instance.'),
					severity: Severity.alert,
				}),
			);
		},
	});

	const onAdd = () => {
		if (currentComposition?.id && selectedElementBase?.id) {
			addInstance({
				compositionId: currentComposition.id,
				elementBaseId: selectedElementBase.id,
			});
		}
	};

	return (
		<Modal centered show={show}>
			<Modal.Header>
				<Modal.Title>
					<Text type='h3'>{label}</Text>
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<TextInput
					className='modal-searchbar'
					placeholder='Suchen...'
					onChange={(value: string) => setSearchValue(value)}
				/>
				<div className='modal-table'>
					<div className='border-bottom-dark'>
						<Text type='pLarge'>{t('name', 'Name')}</Text>
					</div>
					<div className='modal-table-body'>
						<Table striped hover size='sm'>
							<tbody>
								{isLoading && <div>Loading...</div>}
								{elementBases
									?.filter((elementBase) => elementBase.name?.includes(searchValue))
									?.map((elementBase: ComponentSimpleDto | CompositionSimpleDto, index: number) => {
										return (
											<tr
												key={index}
												onClick={() => {
													setSelectedElementBase(elementBase);
												}}
												className={elementBase.id === selectedElementBase?.id ? 'selected' : ''}
											>
												<td>{elementBase.name}</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button type='secondary' onClick={onClose} disabled={isAddingInstance} label={t('cancel', 'Cancel')} />
				{isAddingInstance ? (
					<Spinner animation={'border'} />
				) : (
					<Button
						type='primary'
						onClick={onAdd}
						disabled={selectedElementBase === null}
						label={t('add', 'Add')}
					/>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default AddInstanceModal;
