import { QueryClient } from '@tanstack/react-query';
import { ConnectorDirection } from '../services/warehouse/models/ConnectorDirection';

export interface ToastBar {
	show: boolean;
	message: string;
	severity: Severity;
}

export interface ConfirmationDialogProps {
	show?: boolean;
	title?: string;
	message?: string;
	handleConfirm?: () => void;
	closeLabel?: string;
	confirmLabel?: string;
}

export interface SortingOption {
	property: string;
	ascending: boolean;
}

export enum Severity {
	default = 'default',
	warning = 'warning',
	alert = 'alert',
	success = 'success',
}

export enum ContentType {
	compositions = 'Compositions',
	editor = 'Editor',
	viewer = 'Viewer',
}

export enum ActionModeType {
	None = 'None',
	Create = 'CreateMode',
	CreateVariant = 'CreateVariant',
	Details = 'DetailsMode',
	Edit = 'EditMode',
	Delete = 'DeleteMode',
}

export enum WorkingAreaAction {
	None = 'None',
	AddComponent = 'AddComponent',
	AddComposition = 'AddComposition',
	EditElementInstance = 'EditElementInstance',
}

export enum NodeUsageType {
	Component = 'component',
	Composition = 'composition',
	Variant = 'variant'
}

export enum ElementBaseTypes {
	Component = 'Component',
	Composition = 'Composition',
}

export interface NodeIOData {
	id: string;
	name: string;
	direction: ConnectorDirection;
	classification: string;
}

export interface Coordinates {
	x: number;
	y: number;
}

export type ModalProps = {
	show: boolean;
	onClose: () => void;
}
