import { ToastContainer } from '@lbc-toolkit/toast';
import { ToolkitProvider } from '@lbc-toolkit/toolkit-provider';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FormatIcu } from '@tolgee/format-icu';
import { DevTools, Tolgee, TolgeeProvider } from '@tolgee/react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Config from '../configuration/config';
import { store } from '../context/store';
import UnauthorizedPage from '../pages/unauthorized/components/Unauthorized';
import { PrivateRoute } from '../routes/PrivateRoute';
import { PublicRoute } from '../routes/PublicRoute';
import { OpenAPI as CatalogAPI } from '../services/catalog';
import { OpenAPI as ComposerAPI } from '../services/composer';
import { OpenAPI as ModelManagerAPI } from '../services/modelmanager';
import { OpenAPI as WarehouseAPI } from '../services/warehouse';
import { liebherrTheme } from '../styles/liebherrTheme';
import Dashboard from './dashboard/Dashboard';

// Styling
import './App.scss';

export const queryClient = new QueryClient();

export const tolgee = Tolgee().use(DevTools()).use(FormatIcu()).init({
	apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
	apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
	defaultLanguage: 'de',
});

function App() {
	CatalogAPI.BASE = Config.CatalogApiUrl ?? '';
	ComposerAPI.BASE = Config.ComposerApiUrl ?? '';
	WarehouseAPI.BASE = Config.WarehouseApiUrl ?? '';
	ModelManagerAPI.BASE = Config.ModelManagerApiUrl ?? '';

	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={liebherrTheme}>
					<TolgeeProvider tolgee={tolgee} fallback='Loading...'>
						<div className='App'>
							<ToastContainer position='top-right' width='408px' />
							<ToolkitProvider>
								<Routes>
									<Route
										path='/'
										element={
											<PrivateRoute>
												<Dashboard />
											</PrivateRoute>
										}
									/>
									<Route
										path='/unauthorized'
										element={
											<PublicRoute>
												<UnauthorizedPage />
											</PublicRoute>
										}
									/>
								</Routes>
							</ToolkitProvider>
						</div>
					</TolgeeProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</Provider>
	);
}

export default App;
