// Router
import { Navigate, useLocation } from 'react-router-dom';
// Hooks
import useKeycloak from '../hooks/useKeycloak';

interface PublicRouteProps {
    children: JSX.Element;
}

export const PublicRoute = ({ children }: PublicRouteProps): JSX.Element => {
	const { isInitialized, catalogRoles } = useKeycloak();
	const location = useLocation();

	if (isInitialized) {
		if (catalogRoles && catalogRoles.length > 0) {
			return location.pathname === '/unauthorized' ? <Navigate to='/' /> : children;
		}
	}

	return children;
};
