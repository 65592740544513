import { useState } from "react";
import useVariantEditor from "../../../../../hooks/useVariantEditor";
import { CombinationDto, ElementInstanceDto } from "../../../../../../../../../services/composer";
import { useAppDispatch } from "../../../../../../../../../context/hooks";
import { setIsModified } from "../../../../../../../../../context/editorReducer";

const useCombination = (combination: CombinationDto) => {
    const dispatch = useAppDispatch();
    const { compositionElements } = useVariantEditor();

    const [min, setMin] = useState<number>(1);
    const [max, setMax] = useState<number | null>(null);
    const [minMaxHint, setMinMaxHint] = useState<string>("");
    const [selectedElement, setSelectedElement] = useState<ElementInstanceDto | null>(null);
    const [name, setName] = useState<string>(combination.name??"");
    
    const onMinChange = (minInt: number) => {
        if(max == null || max <= 0){
            setMinMaxHint("");
        }
        else if(minInt > (max ?? 0)){
            setMinMaxHint("Min must be less than max");
        }
        else if (minInt <= 0){
            setMin(0);
        }
        else {
            setMinMaxHint("");
        }

        if(minInt <= 0 && max !== null && max > 0) setMin(0);
        else if(minInt <= 0) setMin(1);
        else setMin(minInt);
    }

    const onMaxChange = (maxInt: number) => {
        if(maxInt == null || maxInt <= 0){
            setMinMaxHint("");
        }
        else if (maxInt < min){
            setMinMaxHint("Min must be less than max");
        }
        else {
            setMinMaxHint("");
        }

        if(maxInt <= 0 && min === 0) {
            setMax(null);
            setMin(1);
        }
        else if(maxInt <= 0) setMax(null);
        else setMax(maxInt);
    }

    const onElementSelect = (elementInstanceId: string) => {
        const element = compositionElements?.contains?.find(element => element.id === elementInstanceId);
        setSelectedElement(element ?? null);
    }

    const validateInput = (): boolean => {
        if(selectedElement === null){
            return false;
        }
        if(min < 0){
            return false;
        }
        if(min === 0 && (max === null || max === 0)){
            return false;
        }
        if(max !== null && max < min){
            return false;
        }
        if(min === 0 && max === null){
            return false;
        }
        return true;
    }

    const onReset = () => {
        setMin(1);
        setMax(null);
        setSelectedElement(null);
    }
    
    const onNameChange = (name: string) => {
        setName(name);
        dispatch(setIsModified(true));
    }

    return (
        {
            min,
            max,
            minMaxHint,
            selectedElement,
            onMinChange,
            onMaxChange,
            onElementSelect,
            validateInput,
            onReset,
            name,
            onNameChange
        }
    );
};

export default useCombination;