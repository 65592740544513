import { useMutation, useQuery } from "@tanstack/react-query";
import { setActionMode, setToast } from "../../../context/appReducer";
import { useAppDispatch } from "../../../context/hooks";
import { ActionModeType, Severity } from "../../../models/models";
import { ComponentDetailsDto, ComponentsService } from "../../../services/warehouse";
import { CompositionSimpleDto, CompositionsService } from "../../../services/composer";
import { setCompositionsListNeedsReload, setActiveComposition } from "../../../context/compositionsReducer";
import { queryClient } from "../../../components/App";

export const useDeleteComposition = (composition: CompositionSimpleDto) => {
	const dispatch = useAppDispatch();

	const { mutate: deleteComposition } = useMutation({
		mutationFn: () => CompositionsService.deleteComposition(composition.id!),
		onSuccess: () => {
            dispatch(setCompositionsListNeedsReload(true));
			dispatch(
				setToast({
					show: true,
					message: 'Composition deleted successfully.',
					severity: Severity.success,
				}),
			);

			queryClient.invalidateQueries({ queryKey: ['compositions'] });
		},
		onError: (error: any) => {
			dispatch(
				setToast({
					show: true,
					message: `Error deleting composition: ${error.message}`,
					severity: Severity.warning,
				}),
			);
		},
	});

	const { data: dependencies, isLoading } = useQuery({
		queryKey: ['composition-dependencies', composition.id!],
		queryFn: () => CompositionsService.getDependencies(composition.id!),
	});

	return {
		deleteComposition,
		dependencies,
		isLoading,
	};
};
