import { useMutation } from "@tanstack/react-query";
import { showToast } from "../context/appReducer";
import { Severity } from "../models/models";
import { useAppDispatch } from "../context/hooks";
import { CompositionBuilderService } from "../services/composer";
import { setIsModified } from "../context/editorReducer";

const useSaveCompositionBuild = () => {
    const dispatch = useAppDispatch();

    const { mutate: saveCompositionBuild, isPending } = useMutation({
		mutationFn: (compositionId: string) => CompositionBuilderService.saveCompositionBuild(compositionId),
		onSuccess: () => {
			dispatch(showToast({ message: 'Composition saved successfully', severity: Severity.success }));
			dispatch(setIsModified(false));
		},
		onError: () => {
			dispatch(showToast({ message: 'Failed to save Composition', severity: Severity.alert }));
		},
	});

    return {
        saveCompositionBuild,
        isPending,
    }
};

export default useSaveCompositionBuild;