import Keycloak from 'keycloak-js';
import Config from '../configuration/config';

const _kc = new Keycloak({
	url: Config.KeycloakUrl,
	realm: Config.KeycloakRealm ?? 'master',
	clientId: Config.KeycloakClientId ?? 'admin-cli',
});

const initKeycloak = (onAuthenticatedCallback: () => void) => {
	_kc.init({
		onLoad: 'login-required',
		checkLoginIframe: false,
	})
		.then((authenticated) => {
			if (authenticated) {
				onAuthenticatedCallback();
			} else {
				doLogin();
			}
		})
		.catch((error: any) => {
			console.log(error);
		});
};

const checkSSO = (onAuthenticatedCallback: () => void) => {
	_kc.init({
		onLoad: 'check-sso',
		checkLoginIframe: false,
	})
		.then((authenticated) => {
			if (authenticated) {
				onAuthenticatedCallback();
			}
		})
		.catch((error: any) => {
			console.log(error);
		});
};

const doLogin = _kc.login;
const doLogout = () => {
	_kc.logout();
};
const updateToken = (successCallback: () => void) => _kc.updateToken(5).then(successCallback).catch(doLogin);
const getUserName = () => _kc.tokenParsed?.preferred_username;
const getName = () => _kc.tokenParsed?.name;
const getMail = () => _kc.tokenParsed?.email;
const hasRoles = (roles: string[]) => roles.some((role: string) => _kc.hasRealmRole(role));
const isLoggedIn = () => !!_kc.token;
const getName2 = () => {
	const token = _kc.token;
	if (!token) return undefined;
	const claims = JSON.parse(atob(token.split('.')[1]));
	return claims.preferred_username;
};

const getToken = () => {
	const token = _kc.token;
	if (!token) return undefined;
	return token;
};
const getClientRoles = (clientId: string) => {
	return _kc.tokenParsed?.resource_access?.[clientId]?.roles;
};
const KeycloakService = {
	initKeycloak,
	checkSSO,
	doLogin,
	doLogout,
	updateToken,
	getUserName,
	getName,
	getMail,
	hasRoles,
	isLoggedIn,
	getName2,
	getToken,
	getClientRoles,
};

export default KeycloakService;
