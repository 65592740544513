import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { Button as LbcButton } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
import { Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import useSaveCompositionBuild from '../../../hooks/useSaveCompositionBuild';
import useEditor from '../hooks/useEditor';
import Breadcrumbs from './Breadcrumbs';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

const EditorHeader = () => {
	const { currentComposition, isLoading } = useEditor();
	const isModified = useAppSelector((state: RootState) => state.editor.isModified);

	const { saveCompositionBuild, isPending } = useSaveCompositionBuild();

	return (
		<div className='flex items-center justify-between'>
			{isLoading ? (
				<Skeleton variant='text' width={200} height={40} />
			) : (
				<div className='flex items-center gap-2'>
					<Breadcrumbs />
					<Text type='h2'>{'/' + currentComposition?.name}</Text>
				</div>
			)}
			{currentComposition && !isLoading && (
				<LbcButton
					disabled={!isModified}
					animation={isPending ? "spin" : undefined}
					icon={isPending ? faSpinner : faSave}
					label='Save Composition'
					onClick={() => saveCompositionBuild(currentComposition.id ?? '')}
				/>
			)}
		</div>
	);
};

export default EditorHeader;
