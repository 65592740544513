//Components
import { Button } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
// Services
import KeycloakService from "../../../services/KeyCloakService";
// Styling
import './Unauthorized.scss'

const UnauthorizedPage = () => {

    const handleLogout = () => {
        KeycloakService.doLogout();
    };

    return (
        <div className="unauthorized-container">
            <Text type='h1'>Zugriff verweigert</Text>
            <Text type='p'>Sie haben nicht die erforderlichen Berechtigungen, um auf diese Seite zuzugreifen.</Text>
            <Button className="logout-button" onClick={handleLogout} label={"Ausloggen"} />
        </div>
    );
}; export default UnauthorizedPage