import { Breadcrumb } from 'react-bootstrap';
import { Skeleton } from '@mui/material';
import useEditor from '../hooks/useEditor';
import { CompositionSimpleDto, ElementBaseDiscriminator } from '../../../services/composer';
import { setActiveComposition } from '../../../context/compositionsReducer';
import { useAppDispatch } from '../../../context/hooks';

const Breadcrumbs = () => {
	const { compositionPath, navigateToComposition, isLoading } = useEditor();
	const dispatch = useAppDispatch();

	if (isLoading) 
		return <Skeleton variant='text' width={150} height={30} />;

	return (
		<Breadcrumb>
			{compositionPath.slice(0, compositionPath.length-1).map((composition: CompositionSimpleDto, index: number) => {
				const active = compositionPath.length === index + 1;
				return (
					<Breadcrumb.Item active={active} key={index} onClick={() => {
								if (active) return;
								if (composition.discriminator === ElementBaseDiscriminator.CompositionVariant) {
									dispatch(setActiveComposition(composition));
								}
								navigateToComposition(composition.id!);
							}}>
							{composition.name!}
					</Breadcrumb.Item>
				);
			})}
		</Breadcrumb>
	);
};

export default Breadcrumbs;
