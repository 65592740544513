/**
 * Downloads a given blob as file.
 * @param blob File to be downloaded
 * @param fileName name of file
 */
function downloadFile(blob: Blob, fileName: string) {
	const url = window.URL.createObjectURL(new Blob([blob]));
	const link = document.createElement('a');
	link.href = url;
	link.download = fileName;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export default downloadFile;