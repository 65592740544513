import { useMutation } from '@tanstack/react-query';
import { setToast } from '../../../../../context/appReducer';
import { addCompositionElementsCombinationEntry, removeCompositionElementsCombinationEntry, setCompositionElements, setIsModified } from '../../../../../context/editorReducer';
import { useAppDispatch, useAppSelector } from '../../../../../context/hooks';
import { RootState } from '../../../../../context/store';
import { Severity } from '../../../../../models/models';
import { AddCombinationEntryDto, CombinationDto, CombinationEntryDto, CompositionBuilderService, ElementInstanceDto } from '../../../../../services/composer';
import useEditor from '../../../hooks/useEditor';

const useVariantEditor = () => {
	const dispatch = useAppDispatch();
	const compositionElements = useAppSelector((state: RootState) => state.editor.compositionElements);
	const { currentComposition, isLoading: editorLoading } = useEditor();

	const addElement = (elementInstance: ElementInstanceDto) => {
		const newElements = [...(compositionElements?.contains ?? [])];
		newElements.push(elementInstance);
		dispatch(setCompositionElements({ ...compositionElements, contains: newElements }));
		dispatch(setIsModified(true));
	};

	const onRemove = (elementInstance: ElementInstanceDto) => {
		if (elementInstance.id && !isElementInCombinations(elementInstance.id)) {
			removeElementInstance(elementInstance.id);
			return;
		}
	};

	const isElementInCombinations = (elementId: string): boolean => {
		return (
			compositionElements?.combinations?.some((combination) =>
				combination.entries?.some((entry) => entry.elementInstanceId === elementId),
			) ?? false
		);
	};

	const changeCombinationName = (combinationId: string, name: string) => {
		if (currentComposition.id)
			renameCombination({ compositionId: currentComposition.id, combinationId: combinationId, name: name });
	};

	const { mutate: removeElementInstance } = useMutation({
		mutationFn: (elementInstanceId: string) => {
			return CompositionBuilderService.removeInstance(currentComposition?.id ?? '', elementInstanceId);
		},
		onSuccess: (data, variables, context) => {
			const filteredElements = compositionElements?.contains?.filter((element) => element.id !== variables);
			dispatch(setCompositionElements({ ...compositionElements, contains: filteredElements }));
			dispatch(setIsModified(true));
		},
		onError: (error) => {
			console.error(error);
			dispatch(
				setToast({
					message: 'Error while removing node',
					severity: Severity.alert,
					show: true,
				}),
			);
		},
	});

	const { mutate: addCombination } = useMutation({
		mutationFn: (compositionId: string) => CompositionBuilderService.addCombination(compositionId),
		onSuccess: (id: string) => {
			const newCombinations = [...(compositionElements?.combinations ?? [])];
			newCombinations.push({
				id: id,
				name: '',
				entries: [],
			});
			dispatch(setCompositionElements({ ...compositionElements, combinations: newCombinations }));

			dispatch(setIsModified(true));
		},
	});

	const { mutate: removeCombination } = useMutation({
		mutationFn: (args: { compositionId: string; combinationId: string }) =>
			CompositionBuilderService.removeCombination(args.compositionId, args.combinationId),
		onSuccess: (data, variables, context) => {
			const newCombinations = [
				...(compositionElements?.combinations?.filter(
					(combination) => combination.id !== variables.combinationId,
				) ?? []),
			];
			dispatch(setCompositionElements({ ...compositionElements, combinations: newCombinations }));

			dispatch(setIsModified(true));
		},
	});

	const { mutate: addCombinationEntry } = useMutation({
		mutationFn: (args: {
			compositionId: string;
			combinationId: string;
			elementInstance: ElementInstanceDto;
			min: number;
			max: number | null;
		}) =>
			CompositionBuilderService.addCombinationEntry(args.compositionId, args.combinationId, {
				elementInstanceId: args.elementInstance.id,
				min: args.min,
				max: args.max,
			} as AddCombinationEntryDto),
		onSuccess: (data, variables, context) => {
			const combinationEntry: CombinationEntryDto = {
				elementInstanceId: variables.elementInstance.id,
				elementInstanceName: variables.elementInstance.name,
				min: variables.min,
				max: variables.max,
			};
			dispatch(
				addCompositionElementsCombinationEntry({
					combinationId: variables.combinationId,
					combinationEntry: combinationEntry,
				}),
			);
			dispatch(setIsModified(true));
		},
		onError: (error, variables, context) => {
			dispatch(
				setToast({
					message: 'Element could not be added to combination',
					severity: Severity.alert,
					show: true,
				}),
			);
		},
	});

	const { mutate: removeCombinationEntry } = useMutation({
		mutationFn: (args: { compositionId: string; combinationId: string }) =>
			CompositionBuilderService.removeCombinationEntry(args.compositionId, args.combinationId),
		onSuccess: (data, variables, context) => {
			dispatch(removeCompositionElementsCombinationEntry(variables.combinationId));
			dispatch(setIsModified(true));
		},
	});

	const { mutate: renameCombination } = useMutation({
		mutationFn: (args: { compositionId: string; combinationId: string; name: string }) =>
			CompositionBuilderService.updateCombination(args.compositionId, args.combinationId, { name: args.name }),
        onSuccess: (data, variables, context) => {
            const newCombinations = compositionElements?.combinations?.map((combination) => {
				if (combination.id === variables.combinationId) {
					return { ...combination, name: variables.name } as CombinationDto;
				}
				return combination;
			}) ?? [];

            dispatch(setCompositionElements({ ...compositionElements, combinations: newCombinations }));
            dispatch(setIsModified(true));
        },
		onError: () => {
			dispatch(setToast({ message: 'Combination could not be renamed', severity: Severity.alert, show: true }));
		},
	});

	return {
		compositionElements,
		addElement,
		removeElement: onRemove,
		isElementInCombinations,
		changeCombinationName,
		addCombination,
		removeCombination,
		addCombinationEntry,
		removeCombinationEntry,
		isLoading: editorLoading,
	};
};

export default useVariantEditor;
