// React
import { useEffect, useRef } from 'react';
import { createEditor } from '../../rete/reteConfig';

// Components
import { LiebherrReteEditor } from '../../rete/LiebherrReteEditor';

// Styles
import './styles/reteEditor.scss';

// Hooks
import useRete from '../../hooks/useRete';
import useEditor from '../../../../hooks/useEditor';

// Services
import { buildDiagramDocument } from './services/DiagramDocumentBuilder';

type ReteEditorProps = {
	editor: LiebherrReteEditor | null;
	setEditor: (editor: LiebherrReteEditor) => void;
};

/**
 * Renders the Rete editor.
 */
export const ReteEditor = ({ editor, setEditor }: ReteEditorProps) => {
	const reteContainer = useRef<HTMLDivElement>(null);
	const { currentComposition } = useEditor();
	const { registerEditorModify, importDiagram } = useRete(editor);

	useEffect(() => {
		if (!reteContainer.current) return;

		setEditor(createEditor(reteContainer.current));
	}, [reteContainer]);

	useEffect(() => {
		importDiagram(buildDiagramDocument(currentComposition));
	}, [currentComposition, editor]);

	useEffect(() => {
		if (!editor) return;

		registerEditorModify();
	}, [editor]);

	return <div ref={reteContainer} id='rete-editor' />;
};