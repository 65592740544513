import { useEffect, useState } from 'react';
import { ClassificationDto, UsageType } from '../../../../services/catalog';
import ClassificationTreeNode from './ClassificationTreeNode';
import dynamicSort from '../../../../utils/DynamicSort';

type ClassificationTreeItemsProps = {
	classifications: ClassificationDto[];
};

const ClassificationTreeItems = ({ classifications }: ClassificationTreeItemsProps) => {
	const [classTreeSorting, setClassTreeSorting] = useState<keyof ClassificationDto>('name');
	const [classTreeAscending, setClassTreeAscending] = useState<boolean>(true);
	const [sortedClassification, setSortedClassification] = useState<ClassificationDto[]>(classifications);

	/**
	 * UseEffect hook to update cranetype list based on sorting options
	 */
	useEffect(() => {
		const tempClassifications = [...classifications];
		tempClassifications.sort(dynamicSort(classTreeSorting, classTreeAscending));
		setSortedClassification(tempClassifications);
	}, [classTreeSorting, classifications]);

	if (classifications.length === 0) return <></>;

	return (
		<ul className='treeview-ul'>
			{sortedClassification
				.filter(
					(children) =>
						children.usageType === UsageType.Composition || children.usageType === UsageType.Collector || children.usageType === UsageType.CompositionVariant,
				)
				.map((children) => (
					<ClassificationTreeNode classification={children} key={children.id} />
				))}
		</ul>
	);
};

export default ClassificationTreeItems;
