// Styling
import './Dashboard.scss';

import { Button } from '@lbc-toolkit/button';
import { showToast } from '@lbc-toolkit/toast';
import { ReactElement, useEffect, useState } from 'react';
import { setToast } from '../../context/appReducer';
import { useAppDispatch, useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import CompositionsOverview from '../../features/CompositionOverview';
import DeleteComposition from '../../features/deleteComposition/DeleteComposition';
import Editor from '../../features/Editor';
import Viewer from '../../features/Viewer';
import DashboardLayout from '../../layouts/DashboardLayout';
import { ContentType, Severity, ToastBar } from '../../models/models';
import LeaveConfirmationDialog from '../modal/ConfirmationDialog/LeaveConfirmationDialog';
import CreateModal from '../modal/CreateModal/CreateModal';
import DetailsModal from '../modal/DetailsModal/DetailsModal';
import BottomNavbar from './navigation/BottomNavbar';
import SideNavbar from './navigation/SideNavbar';
import TopNavbar from './navigation/TopNavbar';
import { useTranslate } from '@tolgee/react';
import useSaveCompositionBuild from '../../hooks/useSaveCompositionBuild';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';

const Dashboard = () => {
	const { t } = useTranslate();
	const [content, setContent] = useState<ReactElement>(<></>);
	const activeContent: ContentType = useAppSelector((state: RootState) => state.app.activeContent);
	const toast: ToastBar = useAppSelector((state: RootState) => state.app.toast);
	const dispatch = useAppDispatch();
	const selectedComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);

	useEffect(() => {
		if (toast.show) {
			activateToast(toast.message, toast.severity);
		}
	}, [toast]);

	useEffect(() => {
		switch (activeContent) {
			case ContentType.compositions:
				setContent(<CompositionsOverview />);
				break;
			case ContentType.viewer:
				setContent(<Viewer compositionId={selectedComposition?.id ?? ''} />);
				break;
			case ContentType.editor:
				setContent(<Editor />);
				break;
			default:
				setContent(<CompositionsOverview />);
		}
	}, [activeContent]);

	/**
	 * Toast gets activated as a central component.
	 * @param message Message showing in the toast.
	 * @param severity Style of the toast.
	 */
	function activateToast(message: string, severity: Severity) {
		showToast({
			message: message,
			type: severity,
			autoHide: true,
			delay: 3000,
		});
		dispatch(
			setToast({
				show: false,
				message: 'clicked',
				severity: Severity.default,
			}),
		);
	}

	return (
		<>
			<CreateModal />
			<DetailsModal />
			{selectedComposition && <DeleteComposition selectedComposition={selectedComposition} />}
			<LeaveConfirmationDialog />
			<DashboardLayout
				header={<TopNavbar />}
				sidebar={<SideNavbar />}
				content={content}
				footer={<BottomNavbar />}
			/>
		</>
	);
};
export default Dashboard;
