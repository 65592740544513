// React
import { useEffect, useState } from 'react';

// Services
import { PropertyAssignmentDto, DataType, SimplePropertyDto } from '../../../../../services/catalog';
import {
	TablePropertyValue,
	PropertyEntryDto,
	ObjectPropertyValue,
	SimplePropertyValue,
} from '../../../../../services/warehouse';

interface Props {
	columnProperties: PropertyAssignmentDto[];
	propertyId: string;
	propertyEntry: PropertyEntryDto;
	isActive: boolean;
	index: number;
	setEntry: React.Dispatch<React.SetStateAction<PropertyEntryDto>>;
}

/**
 * Each Row for the Table in Object-Properties
 * @param param0 The props for the component
 * @returns JSX-Element
 */
const ObjectEntryGrid = ({
	columnProperties,
	propertyId: propertyId,
	propertyEntry,
	setEntry,
	isActive,
	index,
}: Props) => {
	const [tempEntry, setTempEntry] = useState<PropertyEntryDto>(propertyEntry);

	useEffect(() => {
		setEntry(tempEntry);
	}, [tempEntry, setEntry]);

	const getInputType = (dataType?: DataType) => {
		switch (dataType) {
			case DataType.String:
				return 'text';
			case DataType.Double:
			case DataType.Integer:
				return 'number';
			case DataType.Boolean:
				return 'checkbox';
			default:
				return 'text';
		}
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, column: PropertyAssignmentDto) => {
		const targetType = event.target.type;
		const newValue = targetType === 'checkbox' ? event.target.checked.toString() : event.target.value;
		setTempEntry((prevEntry) => {
			const clonedEntry: PropertyEntryDto = { ...prevEntry };
			const objectPropertyValue = clonedEntry.value as ObjectPropertyValue;
			const tablePropertyValue: TablePropertyValue = objectPropertyValue.values?.[
				propertyId
			] as TablePropertyValue;

			const { values: currentValues } = tablePropertyValue;

			if (!currentValues || !column.id) return clonedEntry;

			// Check if the column exists and get the current column's cells
			const currentColumnCells = currentValues[column.id] ?? [];

			// Prepare the updated cells array
			let updatedCells: (ObjectPropertyValue | SimplePropertyValue | TablePropertyValue)[];

			// If there are no cells or the index is beyond the current cells length,
			// we need to add new cells (including placeholders for any missing indices)
			if (currentColumnCells.length === 0 || index >= currentColumnCells.length) {
				// Create placeholders for missing indices, if any
				const placeholders = Array.from(
					{ length: index - currentColumnCells.length + 1 },
					() => ({ discriminator: 'SimplePropertyValue', value: '' } as SimplePropertyValue),
				);
				// Add the new cell with `newValue` at the specified index
				updatedCells = [...currentColumnCells, ...placeholders, { value: newValue }];
			} else {
				// If the column exists and the index is within bounds, update the existing cell value
				updatedCells = currentColumnCells.map((cell, cellIndex) =>
					cellIndex === index ? { ...cell, value: newValue } : cell,
				);
			}

			// Update the `tablePropertyValue` with the updated cells for the specific column
			const updatedColumnValues = {
				...currentValues,
				[column.id]: updatedCells,
			};

			const updatedValues = {
				...objectPropertyValue.values,
				[propertyId]: { ...tablePropertyValue, values: updatedColumnValues },
			};
			const updatedPropertyEntry: PropertyEntryDto = {
				...clonedEntry,
				value: {
					...objectPropertyValue,
					values: updatedValues,
				},
			};
			return updatedPropertyEntry;
		});
	};

	return (
		<>
			{columnProperties.map((column) => {
				let cellValue: string | null = null;
				const tablePropertyValue = (propertyEntry.value as ObjectPropertyValue).values?.[
					propertyId
				] as TablePropertyValue;
				if (tablePropertyValue) {
					cellValue =
						(tablePropertyValue.values?.[column.id!]?.[index] as SimplePropertyValue)?.value ?? null;
				}
				const inputType = getInputType((column.property as SimplePropertyDto)?.dataType);

				return (
					<td key={column.id}>
						{isActive ? (
							<input
								type={inputType}
								value={typeof cellValue === 'string' ? cellValue : ''}
								checked={inputType === 'checkbox' && cellValue === 'true'}
								onChange={(e) => handleInputChange(e, column)}
							/>
						) : (
							cellValue || '-'
						)}
					</td>
				);
			})}
		</>
	);
};

export default ObjectEntryGrid;
