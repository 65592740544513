import { useTranslate } from "@tolgee/react";
import { ModalProps } from "react-bootstrap";
import AddInstanceModal from "../../../../components/AddInstanceModal";
import useVariantEditor from "../../hooks/useVariantEditor";
import { ElementInstanceDto } from "../../../../../../services/composer";
import useComponents from "../../../../../../hooks/useComponents";

type AddComponentInstanceModalProps = {
    onSave: (data: ElementInstanceDto) => void;
} & ModalProps;

const AddComponentInstanceModal = ({show, onClose, onSave}: AddComponentInstanceModalProps) => {
    const { compositionElements } = useVariantEditor();
    const { t } = useTranslate();
    const {components, isLoading} = useComponents();

    const existingIds = new Set(compositionElements?.contains?.map(component => component.instanceOfId));

    const availableComponents = components?.filter(component => 
        !existingIds.has(component.id)
    ) ?? [];

    return <AddInstanceModal 
        label={t('addComponentInstance', "Add component instance")}
        elementBases={availableComponents}
        isLoading={isLoading}
        onClose={onClose}
        show={show}
        onSave={onSave}
    />
};


export default AddComponentInstanceModal;