/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCombinationEntryDto } from '../models/AddCombinationEntryDto';
import type { AddSocketConnectionDto } from '../models/AddSocketConnectionDto';
import type { ElementInstanceDto } from '../models/ElementInstanceDto';
import type { InitCompositionBuildDto } from '../models/InitCompositionBuildDto';
import type { OpenSocketDto } from '../models/OpenSocketDto';
import type { PropertyCollectionDto } from '../models/PropertyCollectionDto';
import type { PropertySetEntryDto } from '../models/PropertySetEntryDto';
import type { RemoveSocketConnectionDto } from '../models/RemoveSocketConnectionDto';
import type { UpdateCombinationDto } from '../models/UpdateCombinationDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompositionBuilderService {

    /**
     * Get the composition details for the given composition id
     * @param compositionId The ID of the composition
     * @param elementBaseId The ID of the element base
     * @returns ElementInstanceDto Success
     * @throws ApiError
     */
    public static addInstance(
compositionId: string,
elementBaseId: string,
): CancelablePromise<ElementInstanceDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositionbuilder/{compositionId}/instances/{elementBaseId}',
            path: {
                'compositionId': compositionId,
                'elementBaseId': elementBaseId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the composition details for the given composition id
     * @param compositionId The ID of the composition
     * @param instanceId The ID of the instance
     * @returns any Success
     * @throws ApiError
     */
    public static removeInstance(
compositionId: string,
instanceId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compositionbuilder/{compositionId}/instances/{instanceId}',
            path: {
                'compositionId': compositionId,
                'instanceId': instanceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the property collection for the specified instance in a composition.
     * @param compositionId The ID of the composition
     * @param instanceId The ID of the instance
     * @returns PropertyCollectionDto Success
     * @throws ApiError
     */
    public static getPropertyCollectionForInstance(
compositionId: string,
instanceId: string,
): CancelablePromise<PropertyCollectionDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositionbuilder/{compositionId}/instances/{instanceId}/propertycollection',
            path: {
                'compositionId': compositionId,
                'instanceId': instanceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Saves the property collection for an instance contained in a composition.
     * @param compositionId The ID of the composition
     * @param instanceId The ID of the instance
     * @param classificationId 
     * @param propertySetEntries 
     * @returns void 
     * @throws ApiError
     */
    public static postCompositionbuilderInstancesPropertycollection(
compositionId: string,
instanceId: string,
classificationId?: string,
propertySetEntries?: Array<PropertySetEntryDto>,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositionbuilder/{compositionId}/instances/{instanceId}/propertycollection',
            path: {
                'compositionId': compositionId,
                'instanceId': instanceId,
            },
            query: {
                'ClassificationId': classificationId,
                'PropertySetEntries': propertySetEntries,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Resets the composition build for the given composition ID.
     * @param compositionId The ID of the composition to reset.
     * @returns void 
     * @throws ApiError
     */
    public static resetBuild(
compositionId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compositionbuilder/{compositionId}',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Saves the composition build with the given composition id.
     * @param compositionId The id of the composition to save.
     * @returns void 
     * @throws ApiError
     */
    public static saveCompositionBuild(
compositionId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositionbuilder/{compositionId}',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Connects the sockets of two instances in a composition build.
     * @param compositionId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static addSocketConnection(
compositionId: string,
requestBody?: AddSocketConnectionDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositionbuilder/{compositionId}/socketconnection',
            path: {
                'compositionId': compositionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Connects the sockets of two instances in a composition build.
     * @param compositionId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static removeSocketConnection(
compositionId: string,
requestBody?: RemoveSocketConnectionDto,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compositionbuilder/{compositionId}/socketconnection',
            path: {
                'compositionId': compositionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the necessary dependencies to build a composition including its hierarchy and connection.
     * @param compositionId The ID of the composition
     * @returns InitCompositionBuildDto Success
     * @throws ApiError
     */
    public static initCompositionBuild(
compositionId: string,
): CancelablePromise<InitCompositionBuildDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositionbuilder/{compositionId}/init',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all open connectors for a composition.
 * Represented by a list of the sockets that would be created for the composition.
     * @param id The id of the composition.
     * @returns OpenSocketDto Success
     * @throws ApiError
     */
    public static getOpenConnectors(
id: string,
): CancelablePromise<Array<OpenSocketDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/compositionbuilder/{id}/open-connectors',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Add an empty combination to a composition.
     * @param compositionId The id of the composition which to add an empty combination.
     * @returns string Success
     * @throws ApiError
     */
    public static addCombination(
compositionId: string,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositionbuilder/{compositionId}/combinations',
            path: {
                'compositionId': compositionId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Remove a combination from a composition.
     * @param compositionId The id of the composition from which to remove the combination.
     * @param combinationId The id of the combination to remove.
     * @returns void 
     * @throws ApiError
     */
    public static removeCombination(
compositionId: string,
combinationId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compositionbuilder/{compositionId}/combinations/{combinationId}',
            path: {
                'compositionId': compositionId,
                'combinationId': combinationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Update the name of a combination.
     * @param compositionId The id of the composition to which the combination belongs.
     * @param combinationId The id of the combination to update.
     * @param requestBody The DTO containing the new name for the combination.
     * @returns void 
     * @throws ApiError
     */
    public static updateCombination(
compositionId: string,
combinationId: string,
requestBody?: UpdateCombinationDto,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compositionbuilder/{compositionId}/combinations/{combinationId}',
            path: {
                'compositionId': compositionId,
                'combinationId': combinationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Create and Append a new entry to a combination in a composition.
     * @param compositionId The id of the composition to which to add the combination entry.
     * @param combinationId The id of the combination to which to add the entry.
     * @param requestBody The DTO containing the data for the new entry.
     * @returns void 
     * @throws ApiError
     */
    public static addCombinationEntry(
compositionId: string,
combinationId: string,
requestBody?: AddCombinationEntryDto,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/compositionbuilder/{compositionId}/combinations/{combinationId}/entries',
            path: {
                'compositionId': compositionId,
                'combinationId': combinationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Remove the last combination entry for a combination in a composition.
     * @param compositionId The id of the composition from which to remove the combination entry.
     * @param combinationId The id of the combination from which to remove the last entry.
     * @returns void 
     * @throws ApiError
     */
    public static removeCombinationEntry(
compositionId: string,
combinationId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/compositionbuilder/{compositionId}/combinations/{combinationId}/entries',
            path: {
                'compositionId': compositionId,
                'combinationId': combinationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
