/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ElementBaseDiscriminator {
    Component = 'Component',
    Composition = 'Composition',
    CompositionVariant = 'CompositionVariant',
    Undefined = 'Undefined',
}
