import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TableFilterState {
	columnsOptionsVisible: boolean;
	descriptionVisibility: boolean;
	classificationVisibility: boolean;
	articleNumberVisibility: boolean;
	sortingOption: string;
}

export const initialState: TableFilterState = {
	columnsOptionsVisible: false,
	descriptionVisibility: true,
	classificationVisibility: true,
	articleNumberVisibility: true,
	sortingOption: 'name',
};

export const tableFiltersSlice = createSlice({
	name: 'tableFilter',
	initialState,
	reducers: {
		setColumnsOptionsVisible: (state, action: PayloadAction<boolean>) => {
			state.columnsOptionsVisible = action.payload;
		},
		setDescriptionVisibility: (state, action: PayloadAction<boolean>) => {
			state.descriptionVisibility = action.payload;
		},
		setClassificationVisibility: (state, action: PayloadAction<boolean>) => {
			state.classificationVisibility = action.payload;
		},
		setArticleNumberVisibility: (state, action: PayloadAction<boolean>) => {
			state.articleNumberVisibility = action.payload;
		},
		setSortingOption: (state, action: PayloadAction<string>) => {
			state.sortingOption = action.payload;
		},
	},
});

export const { setColumnsOptionsVisible, setDescriptionVisibility, setClassificationVisibility, setArticleNumberVisibility, setSortingOption } =
	tableFiltersSlice.actions;

export default tableFiltersSlice.reducer;
