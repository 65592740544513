import { faPenSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@lbc-toolkit/text';
import { T } from '@tolgee/react';
import { useEffect } from 'react';
import useEditor from '../../../../hooks/useEditor';
import { EditorProps } from '../../WorkingArea';
import './styles/readonlyLabel.scss';

/**
 * Renders a label indicating whether the editor is in readonly mode or not.
 */
export const ReadonlyLabel = ({ editor }: EditorProps) => {
	const { isReadonly } = useEditor();

	if (!isReadonly) return <></>;

	return (
		<div className='editable-status-container'>
			<FontAwesomeIcon icon={faPenSlash} />
			<Text type='h6' className='ps-1'>
				<T key='readonly'>Readonly</T>
			</Text>
		</div>
	);
};
