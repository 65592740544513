import { Provider } from 'react-redux';
import { ElementInstanceNode } from './ElementInstanceNode';
import { store } from '../../../../../../context/store';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '../../../../../../components/App';
import { TolgeeProvider } from '@tolgee/react';
import { tolgee } from '../../../../../../components/App';

export const NodeWrapper = (props) => {
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<TolgeeProvider tolgee={tolgee}>
					<ElementInstanceNode {...props} />
				</TolgeeProvider>
			</QueryClientProvider>
		</Provider>
	);
};
