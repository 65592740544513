import { Node, NodeEditor } from 'rete';
import AutoArrangePlugin from "rete-auto-arrange-plugin";
import ElementInstanceComponent from './nodes/ElementInstanceComponent';
import { ElementInstanceDto } from '../../../../../services/composer';

export class LiebherrReteEditor extends NodeEditor {

    /**
     * Constructor. Editor has to be set manually for its creation is async.
     */
    constructor(container: HTMLDivElement) {
        super("demo@0.1.0", container);
        // Initialize AutoArrange plugin
        this.use(AutoArrangePlugin, { margin: { x: 200, y: 200 }, depth: null });
    }

    /**
     * Creates a node with component specific data
     * @returns
     */
    async addElementInstance(component: ElementInstanceDto) {
        const c = new ElementInstanceComponent();
        const n: Node = await c.createNode({ elementInstance: component });
        n.position = [0, 0];
        this.addNode(n);
        // Trigger auto arrange after adding a node
		this.trigger('arrange', { n });
        this.trigger('modified');
    }

    /**
     * Exports the current state of the editor with all nodes and connections in json format.
     * @returns
     */
    export(): string {
        return JSON.stringify(this.toJSON());
    }

    /**
     * Imports given json data.
     * Gets json data, converts it into the required form and imports it into the editor
     * @param diagramDocument
     */
    async import(diagramDocument?: string | null) {
        this.clear();
        if (!diagramDocument) return;
        await this.fromJSON(JSON.parse(diagramDocument));
        this.trigger('process');
        this.nodes.forEach((node) => {
            this.trigger('arrange', node)
        })
    }
}
