import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropertyDto } from '../services/catalog';

export interface DataGridReducerState {
	selectedProp: PropertyDto | null;
}

export const initialState: DataGridReducerState = {
	selectedProp: null,
};

export const dataGridSlice = createSlice({
	name: 'propertyGrid',
	initialState,
	reducers: {
		setSelectedProp: (state, action: PayloadAction<PropertyDto | null>) => {
			state.selectedProp = action.payload;
		},
	},
});

export const { setSelectedProp } = dataGridSlice.actions;

export default dataGridSlice.reducer;
