import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import AddComponentInstanceModal from '../../../components/AddElementsModal/AddComponentInstanceModal';
import AddCompositionInstanceModal from '../../../components/AddElementsModal/AddCompositionInstanceModal';
import { faGear, faGears } from '@fortawesome/pro-solid-svg-icons';
import { ElementBaseDto } from '../../../../../../../services/composer';

type AddElementMenuProps = {
	anchorElement: HTMLElement | null;
	onClose: () => void;
    onAdd: (element: ElementBaseDto) => void;
};

const AddElementMenu = ({ anchorElement, onClose, onAdd }: AddElementMenuProps) => {
	const [showAddComponentModal, setShowAddComponentModal] = useState(false);
	const [showAddCompositionModal, setShowAddCompositionModal] = useState(false);
	const open = Boolean(anchorElement);

	return (
		<>
			<Menu
				anchorEl={anchorElement}
				open={open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={onClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					onClick={() => {
						onClose();
						setShowAddComponentModal(true);
					}}
				>
					<FontAwesomeIcon size='sm' color='black' icon={faGear} style={{ paddingRight: '8px' }} /> Add
					Component
				</MenuItem>
				<MenuItem
					onClick={() => {
						onClose();
						setShowAddCompositionModal(true);
					}}
				>
					<FontAwesomeIcon size='sm' color='black' icon={faGears} style={{ paddingRight: '8px' }} /> Add
					Composition
				</MenuItem>
			</Menu>

			<AddComponentInstanceModal
				show={showAddComponentModal}
				onClose={() => setShowAddComponentModal(false)}
				onSave={onAdd}
			/>
			<AddCompositionInstanceModal
				show={showAddCompositionModal}
				onClose={() => setShowAddCompositionModal(false)}
				onSave={onAdd}
			/>
		</>
	);
};

export default AddElementMenu;
