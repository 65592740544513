// REACT
import { useEffect, useState } from 'react';
// STYLING
import '../Modal.scss';
// COMPONENTS
import { Button as LbcButton } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
import { Modal } from 'react-bootstrap';
import CreateComposition from './CreateComposition';
// API
import { CompositionSimpleDto, CompositionsService, CreateCompositionDto } from '../../../services/composer';
// REDUX
import { setActionMode, setToast } from '../../../context/appReducer';
import {
	setCompositionsListNeedsReload,
	setCreatedComposition,
	setSelectedCompositionClass,
} from '../../../context/compositionsReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
// MODELS
import { ActionModeType, Severity } from '../../../models/models';
// TRANSLATION
import { useTranslate } from '@tolgee/react';
import { queryClient } from '../../App';

const CreateModal = () => {
	const dispatch = useAppDispatch();
	const actionMode = useAppSelector((state: RootState) => state.app.actionMode);
	const [wantsToClose, setWantsToClose] = useState<boolean>(false);
	const [inputsFilled, setInputsFilled] = useState<boolean>(false);
	const [modified, setModified] = useState<boolean>(false);
	const [editComposition, setEditComposition] = useState<CompositionSimpleDto>({});
	const inputClassification = useAppSelector((state: RootState) => state.compositions.selectedCompositionClass);
	const { t } = useTranslate();

	useEffect(() => {
		setEditComposition({
			...editComposition,
			classificationId: inputClassification?.id,
		});
	}, [inputClassification]);

	useEffect(() => {
		setInputsFilled(
			Boolean(editComposition.name?.trim()) &&
				Boolean(editComposition.description?.trim()) &&
				editComposition.classificationId !== null,
		);
	}, [editComposition]);

	useEffect(() => {
		if (actionMode !== ActionModeType.Create) setEditComposition({});
	}, [actionMode]);

	/**
	 * Resets the create modal
	 */
	const ResetCreateModal = () => {
		setEditComposition({});
		dispatch(setActionMode(ActionModeType.None));
		dispatch(setSelectedCompositionClass(null));
		setInputsFilled(false);
		setModified(false);
	};

	/**
	 * Handles the creation of the composition
	 */
	const OnCreate = () => {
		const createComposition: CreateCompositionDto = {
			id: editComposition.id,
			name: editComposition.name,
			description: editComposition.description,
			articleNumber: editComposition.articleNumber,
			classificationId: editComposition.classificationId,
		};

		CompositionsService.createComposition(createComposition)
			.then((response) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('createCompSuccess'),
					}),
				);
				dispatch(setCreatedComposition(response));
				ResetCreateModal();
				dispatch(setCompositionsListNeedsReload(true));

				queryClient.invalidateQueries({ queryKey: ['compositions'] });
			})
			.catch((error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('createFailed'),
					}),
				);
			});
	};

	/**
	 * Handles abort of closing
	 */
	const OnAbortClose = () => {
		setWantsToClose(false);
	};

	/**
	 * Handles confirmation of closing
	 */
	const OnConfirmClose = () => {
		ResetCreateModal();
		setWantsToClose(false);
	};

	/**
	 * Handles closing the create modal
	 */
	const OnCancel = () => {
		if (modified) {
			setWantsToClose(true);
			return;
		}

		OnConfirmClose();
	};

	return (
		<Modal dialogClassName='create-modal-layout' centered show={actionMode === ActionModeType.Create}>
			<Modal.Header>
				<Modal.Title>{t('createComp')}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='create-modal-content'>
				<CreateComposition
					setModified={setModified}
					editComposition={editComposition}
					setEditComposition={setEditComposition}
				/>
			</Modal.Body>
			<Modal.Footer>
				{wantsToClose ? (
					<>
						<Text>{t('wantToClose')}</Text>
						<LbcButton type='secondary' onClick={OnAbortClose} label={t('no')} />
						<LbcButton type='primary' onClick={OnConfirmClose} label={t('yes')} />
					</>
				) : (
					<>
						<LbcButton type='secondary' onClick={OnCancel} label={t('cancel')} />
						<LbcButton type='primary' disabled={!inputsFilled} onClick={OnCreate} label={t('save')} />
					</>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default CreateModal;
