import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useState } from 'react';
import { Entry, VariantOptions } from '../../../services/modelmanager';

interface VariantOptionsRendererProps {
	options: VariantOptions[];
	selectedVariants: { [key: string]: string };
	handleChange: (variantId: string, selectedOption: string) => void;
	parentId?: string;
}

/**
 * VariantOptionSelect component renders selectable variant options and handles their selection.
 * It supports nested variants and entry number inputs.
 * @param {VariantOptions[]} options - The array of variant options available for selection.
 * @param {{ [key: string]: string }} selectedVariants - The currently selected variants.
 * @param {(variantId: string, selectedOption: string) => void} handleChange - Function to handle the change in variant selection.
 * @param {string} [parentId] - The ID of the parent variant (default is an empty string).
 */
const VariantOptionSelect = ({
	options,
	selectedVariants,
	handleChange,
	parentId = '',
}: VariantOptionsRendererProps) => {
	const [entries, setEntries] = useState<{ [key: string]: number }>({});

	/**
	 * Handles the change in entry number input.
	 * @param {Entry} entry - The entry object being changed.
	 * @param {number} newValue - The new value for the entry number.
	 */
	const handleEntryNumberChange = (entry: Entry, newValue: number) => {
		if (entry.maxNumber && newValue > entry.maxNumber) newValue = entry.maxNumber;
		if (entry.minNumber && newValue < entry.minNumber) newValue = entry.minNumber;

		setEntries((prevEntries) => ({
			...prevEntries,
			[entry.elementInstanceId!]: newValue,
		}));

		entry.number = newValue;
	};

	return (
		<>
			{options.map((variant) => {
				const fullVariantId = `${parentId}${variant.variantId}`;
				const selectedOption = selectedVariants[fullVariantId] || '';

				const selectedOptionObject = variant.selectableOptions?.find((opt) => opt.id === selectedOption);

				return (
					<div className='mb-4 ml-4'>
						<FormControl fullWidth margin='normal'>
							<InputLabel>{variant.variantName}</InputLabel>
							<Select
								sx={{ textAlign: 'left' }}
								label={variant.variantName}
								value={selectedOption}
								onChange={(e) => handleChange(fullVariantId, e.target.value)}
							>
								{variant.selectableOptions?.map((option) => (
									<MenuItem key={option.id} value={option.id!}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{selectedOptionObject?.entryNumbers &&
							selectedOptionObject.entryNumbers.map((entry) => (
								<div className='px-2 text-left'>
									{entry.elementName}:
									<TextField
										name={entry.elementName ?? ''}
										value={entries[entry.elementInstanceId!] ?? entry.number}
										onChange={(e) => handleEntryNumberChange(entry, parseInt(e.target.value, 10))}
										variant='standard'
										type='number'
										style={{ width: '75px', marginLeft: '10px' }}
										InputLabelProps={{ shrink: true }}
									/>
								</div>
							))}
						{selectedOptionObject?.variantOptions && (
							<VariantOptionSelect
								options={selectedOptionObject.variantOptions}
								selectedVariants={selectedVariants}
								handleChange={handleChange}
								parentId={`${fullVariantId}-`}
							/>
						)}
					</div>
				);
			})}
		</>
	);
};

export default VariantOptionSelect;
