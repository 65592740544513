/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VariantOptions } from '../models/VariantOptions';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IfcService {

    /**
     * @param compositionId 
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static getIfc4X2(
compositionId: string,
requestBody?: Array<VariantOptions>,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ifc/{compositionId}',
            path: {
                'compositionId': compositionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
