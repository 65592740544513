import { Socket } from 'rete-react-render-plugin';
import { Node as ReteNode} from 'rete';
import Node from '../../../../../components/Node/Node';
import { InputSocket, OutputSocket } from '../../../rete/nodes/CustomSocket';
import { LiebherrReteEditor } from '../../../rete/LiebherrReteEditor';
import useEditor from '../../../../../hooks/useEditor';
import useRete from '../../../hooks/useRete';
import { ElementBaseDiscriminator, ElementInstanceDto } from '../../../../../../../services/composer';

type WorkingAreaNodeProps = {
	node: ReteNode;
	outputs: any;
	inputs: any;
	editor: LiebherrReteEditor;
	bindSocket: any;
	elementInstance: ElementInstanceDto;
	isSelected: boolean;
};

const WorkingAreaNode = ({ node, outputs, inputs, editor, bindSocket, elementInstance }: WorkingAreaNodeProps) => {
	const { removeElementInstance, selectedOpenSocket } = useRete(editor);
	const { isReadonly, navigateToComposition } = useEditor();

	const onRemove = () => {
		removeElementInstance(elementInstance.id!);
	};

	const canJumpInto = elementInstance.instanceOfType === ElementBaseDiscriminator.Composition || elementInstance.instanceOfType === ElementBaseDiscriminator.CompositionVariant;

	return (
		<Node
			elementInstance={elementInstance} 
			isReadonly={isReadonly} 
			onRemove={onRemove} 
			onNavigate={canJumpInto ? navigateToComposition : undefined}>
				{inputs.map((input: InputSocket) => {
					return (
						<div
							key={input.key}
							className={`input ${selectedOpenSocket?.basedOn?.includes(input.key) ? 'hover' : ''} `}
						>
							<Socket type='input' socket={input.socket} io={input} innerRef={bindSocket} />
							<div className='input-title'>{input.name}</div>
						</div>
					);
				})}
				{outputs.map((output: OutputSocket) => {
					return (
						<div
							key={output.key}
							className={`output ${selectedOpenSocket?.basedOn?.includes(output.key) ? 'hover' : ''} `}
						>
							<div className='output-title'>{output.name}</div>
							<Socket type='output' socket={output.socket} io={output} innerRef={bindSocket} />
						</div>
					);
				})}
			</Node>
	);
};

export default WorkingAreaNode;
