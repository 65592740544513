import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { TablePropertyValue, SimplePropertyValue } from '../../../../../services/composer';

type NumberPropertyProps = {
	editMode: boolean;
	unit?: string;
	value: TablePropertyValue | SimplePropertyValue | null | undefined;
	onChange: (value: string) => void;
	decimal: boolean;
};

const NumberProperty = ({ editMode, unit, value, onChange, decimal }: NumberPropertyProps) => {
	const { t } = useTranslate();
	const [doubleValue, setDoubleValue] = useState<number>();

	useEffect(() => {
		let sanitizedValue = (value as SimplePropertyValue).value
		if(sanitizedValue){
			sanitizedValue = sanitizedValue.replace(/^0+/, '');
		}
		const parsedValue = Number(sanitizedValue);
		if (isNaN(parsedValue)) return;
		if (!decimal && parsedValue % 1 !== 0) return;
		setDoubleValue(parsedValue);
	}, [value]);

	const onValueChange = (inputValue: string) => {
		const sanitizedValue = inputValue.replace(/^0+/, '');
		const parsedValue = Number(sanitizedValue);
		if (isNaN(parsedValue)) return;
		if (!decimal && parsedValue % 1 !== 0) return;
		onChange(inputValue);
	};

	return editMode ? (
		<>
			<input
				type={'number'}
				value={doubleValue}
				onChange={(e) => {
					onValueChange(e.target.value);
				}}
				className='form-control form-control-sm'
				step={decimal ? 0.01 : 1}
			/>
		</>
		// <TextInput
		// 	type={'number'}
		// 	placeholder={t('enterValue')}
		// 	value={doubleValue}
		// 	onChange={onChange}
		// 	step={decimal ? 0.01 : 1}
		// />
	) : Boolean(value) ? (
		`${doubleValue} ${unit ? unit : ""}`
	) : (
		t('noValue')
	);
};

export default NumberProperty;
