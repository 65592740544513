import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Button } from 'react-bootstrap';
// Style
import '../../../WorkingArea.scss';

type ToolbarButtonProps = {
    icon: any;
    tooltip: string;
    onClick: () => void;
    disabled?: boolean;
}

const ToolbarButton = ({icon, tooltip, onClick, disabled = false }: ToolbarButtonProps) => {

    return (
        <Tooltip title={tooltip} placement='right'>
            <Button
                className='toolbar-button'
                onClick={onClick}
                disabled={disabled}
                >
                <FontAwesomeIcon className='' icon={icon} />
            </Button>
        </Tooltip>
    );
}

export default ToolbarButton;