/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectorInstanceDetailsDto } from '../models/ConnectorInstanceDetailsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConnectorInstancesService {

    /**
     * Creates new connector instance
     * @param componentId Identifier of the component that connector instance belongs
     * @param connectorId Identifier of the connector type that connector instances created from
     * @returns ConnectorInstanceDetailsDto Success
     * @throws ApiError
     */
    public static createConnectorInstance(
componentId?: string,
connectorId?: string,
): CancelablePromise<Array<ConnectorInstanceDetailsDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/connectorinstances',
            query: {
                'componentId': componentId,
                'connectorId': connectorId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets connector instance by given id.
     * @param connectorInstanceId Identifier of the connector instance
     * @returns ConnectorInstanceDetailsDto Success
     * @throws ApiError
     */
    public static getConnectorInstance(
connectorInstanceId: string,
): CancelablePromise<ConnectorInstanceDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connectorinstances/{connectorInstanceId}',
            path: {
                'connectorInstanceId': connectorInstanceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an existing connector instance
     * @param connectorInstanceId Identifier of the connector instance
     * @returns void 
     * @throws ApiError
     */
    public static deleteConnectorInstance(
connectorInstanceId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/connectorinstances/{connectorInstanceId}',
            path: {
                'connectorInstanceId': connectorInstanceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all connector instances by given component.
     * @param componentId Identifier of the component
     * @returns ConnectorInstanceDetailsDto Success
     * @throws ApiError
     */
    public static getConnectorInstancesByComponent(
componentId: string,
): CancelablePromise<Array<ConnectorInstanceDetailsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/connectorinstances/bycomponent/{componentId}',
            path: {
                'componentId': componentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates new connector instance
     * @param componentId Identifier of the connector instance
     * @param requestBody List of updated connector instances
     * @returns ConnectorInstanceDetailsDto Success
     * @returns string Created
     * @throws ApiError
     */
    public static updateConnectorInstance(
componentId: string,
requestBody?: Array<ConnectorInstanceDetailsDto>,
): CancelablePromise<Array<ConnectorInstanceDetailsDto> | string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/connectorinstances/{componentId}',
            path: {
                'componentId': componentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
