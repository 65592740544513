import { faPen, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';

type ActionButtonsProps = {
	onEdit: () => void;
	onDelete: () => void;
};

export const ActionButtons = (props: ActionButtonsProps) => {
	return (
		<>
			<IconButton color='secondary' onClick={props.onEdit}>
				<FontAwesomeIcon color='#3489eb' className='fa-xs' icon={faPen} />
			</IconButton>
			<IconButton color='error' onClick={props.onDelete}>
				<FontAwesomeIcon color='#eb4034' className='fa-xs' icon={faTrash} />
			</IconButton>
		</>
	);
};
