import { useMutation } from '@tanstack/react-query';
import { IfcService, VariantOptions } from '../../../services/modelmanager';
import downloadFile from '../../../utils/downloadFile';
import { useDispatch } from 'react-redux';
import { useTranslate } from '@tolgee/react';
import { showToast } from '../../../context/appReducer';

const useIfcExport = (compositionId: string, variantOptions: VariantOptions[], compositionName?: string) => {
	const { t } = useTranslate();
	const dispatch = useDispatch();

	const { mutate: downloadIfc, isPending } = useMutation({
		mutationFn: async () => IfcService.getIfc4X2(compositionId, variantOptions),
		onSuccess: (ifc) => {
			downloadFile(new Blob([ifc]), (compositionName ?? 'composition') + '.ifc');
		},
		onError: () => {
			dispatch(showToast({ message: t('viewer.error.download_ifc') }));
		},
	});

	return {
		downloadIfc,
		isPending,
	};
};

export default useIfcExport;
