// React
import { useState, useEffect } from 'react';
// Services
import { OpenAPI as catalogAPI} from '../services/catalog/core/OpenAPI';
import { OpenAPI as warehouseAPI } from '../services/warehouse';
import { OpenAPI as composerAPI} from '../services/composer';
import { OpenAPI as modelManagerAPI} from '../services/modelmanager';
// Services
import KeycloakService from '../services/KeyCloakService';

const useKeycloak = () => {
	const [isInitialized, setIsInitialized] = useState(false);
	const [userName, setUserName] = useState<string | null>(null);
	const [token, setToken] = useState<string | undefined>();
	const [tokenInitialized, setTokenInitialized] = useState<boolean>(false);

	useEffect(() => {
		KeycloakService.initKeycloak(() => {
			setIsInitialized(true);
			setUserName(KeycloakService.getUserName());
			setToken(KeycloakService.getToken());
		});
	}, []);

    useEffect(() => {
		if(!token) return
		console.log(token)
        catalogAPI.TOKEN = token;
		warehouseAPI.TOKEN = token;
		composerAPI.TOKEN = token;
		modelManagerAPI.TOKEN = token;
		setTokenInitialized(true)
    }, [token])

	return {
        isInitialized,
		userName,
		token,
		tokenInitialized,
		login: KeycloakService.doLogin,
		logout: KeycloakService.doLogout,
		updateToken: KeycloakService.updateToken,
		catalogRoles: KeycloakService.getClientRoles('catalog'),
	};
};

export default useKeycloak;