import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../../../context/hooks';
import { CompositionBuilderService, OpenSocketDto } from '../../../../../../../services/composer';
import { setSelectedOpenSocket } from '../../../../../../../context/editorReducer';

const useOpenSockets = (compositionId: string) => {
	const dispatch = useAppDispatch();
	const [clickedOpenSocket, setClickedOpenSocket] = useState<OpenSocketDto | null>(null);

	const { data, isPending, refetch, isError } = useQuery({
		queryKey: ['openSockets', compositionId],
		queryFn: () => CompositionBuilderService.getOpenConnectors(compositionId),
	});

	const onSelection = (selected: OpenSocketDto | null) => {
		dispatch(setSelectedOpenSocket(selected));
	};

    /**
     * set the selected open socket, if the open socket is already selected, deselect it
     * @param openSocket 
     */
	const onClick = (openSocket: OpenSocketDto) => {
        if(openSocket === clickedOpenSocket) {
            setClickedOpenSocket(null);
            onSelection(null);
        }else{
            setClickedOpenSocket(openSocket);
            onSelection(openSocket);
        }
	};

    /**
     * set the selected open socket on hover
     * on leave the clicked open socket will be highlighted.
     */
	const onHover = (openSocket?: OpenSocketDto) => {
		if (openSocket) {
			onSelection(openSocket);
		} else {
			onSelection(clickedOpenSocket);
		}
	};

	return { openSockets: data ?? [], isPending, isError, refetch, onClick, onHover };
};

export default useOpenSockets;
