import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@lbc-toolkit/button';
import { Text } from '@lbc-toolkit/text';
import { useTranslate } from '@tolgee/react';
import { Divider } from '../Divider';

interface HeaderProps {
	titleKey: string;
	icon: IconProp;
	onCreate: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Header: React.FC<HeaderProps> = ({ titleKey, icon, onCreate }) => {
	const { t } = useTranslate();

	return (
		<div className='flex flex-row items-center gap-3'>
			<Text className='' type='h2'>
				{t(titleKey)}
			</Text>
			<FontAwesomeIcon size='2x' icon={icon} swapOpacity />
			<Divider orientation='vertical'/>
			<Button icon={faPlus as IconProp} label={t('createNew')} onClick={onCreate} />
		</div>
	);
};

export default Header;
