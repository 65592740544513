import { useEffect, useState } from 'react';
import { PropertyAssignmentDto, DataType, SimplePropertyDto } from '../../../../services/catalog';
import { TablePropertyValue, PropertyEntryDto, SimplePropertyValue } from '../../../../services/warehouse';

interface Props {
	columnProperties: PropertyAssignmentDto[];
	propertyEntry: PropertyEntryDto;
	isActive: boolean;
	index: number;
	setEntry: React.Dispatch<React.SetStateAction<PropertyEntryDto>>;
}

const TablePropertyEntryGrid = ({ columnProperties, propertyEntry, setEntry, isActive, index }: Props) => {
	const [tempEntry, setTempEntry] = useState<PropertyEntryDto>(propertyEntry);
	useEffect(() => {
		if (!isActive) {
			setEntry(tempEntry);
		}
	}, [isActive, setEntry, tempEntry]);

	const getInputType = (dataType?: DataType) => {
		switch (dataType) {
			case DataType.String:
				return 'text';
			case DataType.Double:
			case DataType.Integer:
				return 'number';
			case DataType.Boolean:
				return 'checkbox';
			default:
				return 'text';
		}
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		column: PropertyAssignmentDto,
		rowIndex: number,
	) => {
		let newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		if (typeof newValue === 'boolean') {
			newValue = newValue.toString();
		}
		let clonedPropertyEntry = JSON.parse(JSON.stringify(tempEntry));
		if (!clonedPropertyEntry.value!.values![column.id!][index]) {
			clonedPropertyEntry.value!.values![column.id!][index].push({
				discriminator: 'Simple',
				value: null,
			} as SimplePropertyValue);
		}
		clonedPropertyEntry.value!.values![column.id!][index].value = newValue;
		setTempEntry(clonedPropertyEntry);
	};

	return (
		<>
			{columnProperties.map((column, rowIndex) => {
				const value = (
					(propertyEntry.value as TablePropertyValue)?.values?.[column.id!][index] as SimplePropertyValue
				).value;
				return isActive ? (
					<td key={column.id}>
						<input
							type={getInputType((column.property as SimplePropertyDto)?.dataType)}
							defaultValue={
								typeof value === 'boolean' || value === null || value === undefined ? '' : value
							}
							checked={value === true.toString()}
							onChange={(e) => handleInputChange(e, column, rowIndex)}
						/>
					</td>
				) : (
					<td key={column.id}>{value === null || value?.length === 0 ? '-' : value}</td>
				);
			})}
		</>
	);
};
export default TablePropertyEntryGrid;
