import { ElementInstanceDto, InitCompositionBuildDto } from "../../../../../../../services/composer";

interface Connection {
    node: number;
    input?: string;
    output?: string;
    data: any;
}

interface Node {
    id: number;
    data: {
        elementInstance: ElementInstanceDto;
    };
    inputs: Record<string, { connections: Connection[] }>;
    outputs: Record<string, { connections: Connection[] }>;
    position: [number, number];
    name: string;
}

/**
 * Initializes a node with given element instance data and position.
 * @param elementInstanceDto - The data of the element instance.
 * @param index - The index of the node.
 * @param currentX - The current X position.
 * @param currentY - The current Y position.
 * @returns The initialized node.
 */
const initializeNode = (elementInstanceDto: ElementInstanceDto, index: number, currentX: number, currentY: number): Node => {
    const nodeId = (index + 1);
    const node: Node = {
        id: nodeId,
        data: {
            elementInstance: elementInstanceDto,
        },
        inputs: {} as Record<string, { connections: Connection[] }>,
        outputs: {} as Record<string, { connections: Connection[] }>,
        position: [currentX, currentY],
        name: 'ElementInstance',
    };

    // Initialize sockets with empty connections
    elementInstanceDto.sockets?.forEach((socket) => {
        if (socket.direction === 'In') {
            node.inputs[socket.id!] = { connections: [] };
        } else if (socket.direction === 'Out') {
            node.outputs[socket.id!] = { connections: [] };
        }
    });

    return node;
};

/**
 * Adds connections to the given node based on the element instance data and initial composition build.
 * @param node - The node to which connections are added.
 * @param elementInstanceDto - The data of the element instance.
 * @param initCompositionBuild - The initial composition build data.
 */
const addConnectionsToNode = (node: Node, elementInstanceDto: ElementInstanceDto, initCompositionBuild: InitCompositionBuildDto): void => {
    elementInstanceDto.sockets?.forEach((socket) => {
        if (socket.connectedToSocketId) {
            initCompositionBuild.contains?.forEach((elementInstanceDtoInner, innerIndex: number) => {
                elementInstanceDtoInner.sockets?.forEach((tempSocket) => {
                    if (socket.connectedToSocketId === tempSocket.id) {
                        const connectedNodeId = (innerIndex + 1);
                        if (socket.direction === 'In') {
                            node.inputs[socket.id!] = {
                                connections: [
                                    {
                                        node: connectedNodeId,
                                        output: tempSocket.id!,
                                        data: {}
                                    },
                                ],
                            };
                        } else if (socket.direction === 'Out') {
                            node.outputs[socket.id!] = {
                                connections: [
                                    {
                                        node: connectedNodeId,
                                        input: tempSocket.id!,
                                        data: {}
                                    },
                                ],
                            };
                        }
                    }
                });
            });
        }
    });
};

/**
 * Builds the diagram document based on the initial composition build data.
 * @param initCompositionBuild - The initial composition build data.
 * @returns The diagram document as a JSON string.
 */
export const buildDiagramDocument = (initCompositionBuild: InitCompositionBuildDto): string => {
    const diagram = {
        id: 'demo@0.1.0',
        nodes: {} as Record<string, Node>,
    };

    initCompositionBuild.contains?.forEach((elementInstanceDto, index) => {
        let node = initializeNode(elementInstanceDto, index, 0, 0);
        addConnectionsToNode(node, elementInstanceDto, initCompositionBuild);
        diagram.nodes[node.id] = node;
    });

    return JSON.stringify(diagram, null, 2);
}
