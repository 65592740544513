const Config = {
	CatalogApiUrl: process.env.REACT_APP_CATALOG_SERVER_URL,
	ComposerApiUrl: process.env.REACT_APP_COMPOSER_SERVER_URL,
	WarehouseApiUrl: process.env.REACT_APP_WAREHOUSE_SERVER_URL,
	ModelManagerApiUrl: process.env.REACT_APP_MODELMANAGER_SERVER_URL,
	WarehouseClientUrl: process.env.REACT_APP_WAREHOUSE_CLIENT_URL,
    CatalogClientUrl: process.env.REACT_APP_CATALOG_CLIENT_URL,
	KeycloakUrl: process.env.REACT_APP_KEYCLOAK_URL,
    KeycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM,
    KeycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export default Config;
