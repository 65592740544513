import { configureStore } from '@reduxjs/toolkit';
import appReducer from './appReducer';
import compositionsReducer from './compositionsReducer';
import dataGridReducer from './dataGridReducer';
import tableFilterReducer from './tableFilterReducer';
import editorReducer from './editorReducer';

export const store = configureStore({
	reducer: {
		app: appReducer,
		compositions: compositionsReducer,
		tableFilter: tableFilterReducer,
		propertyGrid: dataGridReducer,
		editor: editorReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
