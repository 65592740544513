import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@lbc-toolkit/button';
import { Typography } from '@mui/material';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { SelectableOption, VariantOptions } from '../../../services/modelmanager';
import VariantOptionSelect from './VariantOptionSelect';

interface Props {
	variantOptions: VariantOptions[];
	updateState: (newOptions: VariantOptions[]) => void;
	isLoading: boolean;
}

/**
 * VariantSelector component allows users to customize variant options.
 * It handles the selection of variant options and updates the state accordingly.
 * @param {VariantOptions[]} variantOptions - The array of variant options available for selection.
 * @param {(newOptions: VariantOptions[]) => void} updateState - The function to update the state with the new variant options.
 * @param {boolean} isLoading - A flag indicating whether the request to fetch the gltf is pending.
 */
const VariantSelector = ({ variantOptions, updateState, isLoading }: Props) => {
	const { t } = useTranslate();
	const [selectedVariants, setSelectedVariants] = useState<{ [key: string]: string }>({});

	/**
	 * Handles the change in variant selection.
	 * @param {string} variantId - The ID of the variant being changed.
	 * @param {string} selectedOption - The selected option for the variant.
	 */
	const handleChange = (variantId: string, selectedOption: string) => {
		setSelectedVariants((prevState) => ({
			...prevState,
			[variantId]: selectedOption,
		}));
	};

	/**
	 * Updates the variant options with the selected variants.
	 * @param {VariantOptions[]} options - The current variant options.
	 * @param {{ [key: string]: string }} selectedVariants - The selected variants.
	 * @param {string} parentId - The ID of the parent variant (default is an empty string).
	 * @returns {VariantOptions[]} - The updated variant options.
	 */
	const updateVariantOptions = (
		options: VariantOptions[],
		selectedVariants: { [key: string]: string },
		parentId: string = '',
	): VariantOptions[] => {
		return options.map((option) => {
			const fullVariantId = `${parentId}${option.variantId}`;
			const updatedOption: VariantOptions = { ...option };

			if (selectedVariants[fullVariantId]) {
				updatedOption.selectedOption = selectedVariants[fullVariantId];
			}

			if (updatedOption.selectableOptions) {
				const selectableOptions = updatedOption.selectableOptions as SelectableOption[];
				const updatedSelectableOptions = selectableOptions.map((selectableOption) => {
					const updatedSelectableOption = { ...selectableOption };
					if (updatedSelectableOption.variantOptions) {
						updatedSelectableOption.variantOptions = updateVariantOptions(
							updatedSelectableOption.variantOptions,
							selectedVariants,
							`${fullVariantId}-`,
						);
					}
					return updatedSelectableOption;
				});
				updatedOption.selectableOptions = updatedSelectableOptions;
			}

			return updatedOption;
		});
	};

	/**
	 * Handles the creation of the new variant options.
	 */
	const handleGenerate = () => {
		const updatedVariantOptions = updateVariantOptions([...variantOptions], selectedVariants);
		updateState(updatedVariantOptions);
	};

	return (
		<div className='flex h-full flex-col'>
			{variantOptions.length === 0 ? (
				'Keine Varianten vorhanden.'
			) : (
				<>
					<Typography variant='h5' gutterBottom>
						Customize your Variants
					</Typography>
					<div className='max-h-[60vh] flex-1 overflow-y-auto'>
						<VariantOptionSelect
							options={variantOptions}
							selectedVariants={selectedVariants}
							handleChange={handleChange}
						/>
					</div>
					<Button
						disabled={isLoading}
						animation={isLoading && 'spin'}
						icon={isLoading ? faSpinner : faScrewdriverWrench}
						label={t('generate')}
						onClick={handleGenerate}
					/>
				</>
			)}
		</div>
	);
};

export default VariantSelector;
