import { string } from 'prop-types';
import { Input, Output, Socket } from 'rete';

const sockets: { [key: string]: Socket} = {};

export class InputSocket extends Input {
	constructor(key: string, title: string) {
		if(!sockets[title]) { sockets[title] = new Socket(title); }
		super(key, title, sockets[title], false);
	}
}

export class OutputSocket extends Output {
	constructor(key: string, title: string) {
		if(!sockets[title]) { sockets[title] = new Socket(title); }
		super(key, title, sockets[title], false);
	}
}
