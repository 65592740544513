// Hooks
import { useTranslate } from '@tolgee/react';
import useCompositions from '../../hooks/useCompositions';

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNodes, faGears } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// Material-React-Table
import { MRT_ColumnDef } from 'material-react-table';

// Components
import { DataTable } from '../../components/overview/DataTable';
import Header from '../../components/overview/Header';
import { CompositionActionButtons } from './features/CompositionActionButtons';

// Context
import { setActionMode } from '../../context/appReducer';
import { setActiveComposition } from '../../context/compositionsReducer';
import { useAppDispatch } from '../../context/hooks';

// Models & Models
import { ActionModeType } from '../../models/models';
import { CompositionSimpleDto } from '../../services/composer';

export const CompositionsOverview = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslate();
	const { compositions, isLoading } = useCompositions();

	const columns: MRT_ColumnDef<CompositionSimpleDto>[] = [
		{
			accessorKey: 'name',
			header: t('name'),
		},
		{
			accessorKey: 'description',
			header: t('description'),
		},
		{
			accessorKey: 'discriminator',
			header: 'Type',
			id: 'discriminator',
			enableHiding: true,
			Cell: ({ cell }) => {
				const value = cell.getValue<string>();
				const icon = value === 'Composition' ? faGears : faCircleNodes;
				const text = value === 'Composition' ? 'Composition' : 'Variant';

				return (
					<div className='flex items-center gap-2'>
						<FontAwesomeIcon
							className='treeview-listitem-indicator'
							color='black'
							icon={icon as IconProp}
						/>
						{text}
					</div>
				);
			},
		},
	];

	const onCreate = () => {
		dispatch(setActiveComposition(null));
		dispatch(setActionMode(ActionModeType.Create));
	};

	return (
		<div className='h-100 w-100 flex flex-col gap-2 p-4'>
			<Header titleKey='compositions' icon={faGears} onCreate={onCreate} />
			<div className='h-0 flex-grow'>
				{/* flex-grow h-0 is a workaround. Flexgrow makes the table large enough.*/}
				<DataTable
					columns={columns}
					data={compositions ?? []}
					actionButtons={({ row }) => (
						<>
							<CompositionActionButtons composition={row.original} />
						</>
					)}
					state={{ isLoading: isLoading }}
				/>
			</div>
		</div>
	);
};
