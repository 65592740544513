import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { CompositionSimpleDto, CompositionsService } from "../services/composer";
import { useEffect } from "react";
import { setToast } from "../context/appReducer";
import { Severity } from "../models/models";
import { useTranslate } from "@tolgee/react";

const useCompositions = () => {
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const { data: compositions, isLoading, isError, refetch } = useQuery({
        queryKey: ['compositions'],
        queryFn: CompositionsService.getCompositions,
    });

    useEffect(() => {
        if (isError) {
            dispatch(setToast({
                message: t('errorLoadingCompositions', "Error loading compositions. Please try again later."),
                severity: Severity.alert, 
                show: true,
            }));
        }
    }, [isError])

    return {
        compositions: compositions ?? [] as CompositionSimpleDto[],

        isLoading,
        isError,
        refetch
    }
}

export default useCompositions;