import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { setToast } from '../../../../../context/appReducer';
import { setIsModified } from '../../../../../context/editorReducer';
import { useAppDispatch, useAppSelector } from '../../../../../context/hooks';
import { RootState } from '../../../../../context/store';
import { Severity } from '../../../../../models/models';
import { AddSocketConnectionDto, CompositionBuilderService, RemoveSocketConnectionDto } from '../../../../../services/composer';
import useEditor from '../../../hooks/useEditor';
import { LiebherrReteEditor } from '../rete/LiebherrReteEditor';
import { InputSocket, OutputSocket } from '../rete/nodes/CustomSocket';
import { ElementInstanceNode } from '../rete/nodes/ElementInstanceNode';
import { Connection } from 'rete';

/**
 * Custom hook for managing the Rete editor.
 *
 * @param editor - The Rete editor instance.
 * @returns An object containing functions and state related to the Rete editor.
 */
const useRete = (editor: LiebherrReteEditor | null) => {
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const isSilenced = useRef(false);
	const { selectedOpenSocket } = useAppSelector((state: RootState) => state.editor);
	const { currentComposition, isReadonly } = useEditor();

	const isReadonlyRef = useRef(isReadonly);

	useEffect(() => {
		isReadonlyRef.current = isReadonly;
	}, [isReadonly]);

	/**
	 * Registers event listeners for editor modifications.
	 * When nodes or connections are created, removed, or modified it sets the isModified state to true.
	 * Additionally, it handles connection creation and removal events.
	 */
	const registerEditorModify = () => {
		editor?.on(['modified'], () => {
			dispatch(setIsModified(true));
			if (currentComposition?.id) {
				queryClient.invalidateQueries({ queryKey: ['getGltf', currentComposition.id] })
			}
		});
		editor?.on('process', () => {
			dispatch(setIsModified(false));
		});

		editor?.on(['connectionpick'], () => {
			if(!isSilenced.current && isReadonlyRef.current) return false;
		})

		editor?.on('connectioncreated', (connection) => {
			if (isSilenced.current) return;

			addConnection(connection);
		});
		editor?.on('connectionremove', (connection) => {
			if (isSilenced.current) return;

			removeConnection(connection);
		});

	};

	/**
	 * Mutation for adding a connection to the composition.
	 */
	const { mutate: addConnection } = useMutation({
		mutationFn: (connection: Connection) => {
			const { input, output } = connection;
			const inputNode = input.node as ElementInstanceNode | null;
			const outputNode = output.node as ElementInstanceNode | null;

			const dto = {
				sourceInstanceId: inputNode?.data.elementInstance.id,
				targetInstanceId: outputNode?.data.elementInstance.id,
				sourceSocketId: input.key,
				targetSocketId: output.key,
			} as AddSocketConnectionDto;

			return CompositionBuilderService.addSocketConnection(currentComposition?.id ?? '', dto);
		},
		onSuccess: () => {
			editor?.trigger('modified');
		},
		onError: (error, variables) => {
			editor?.removeConnection(variables);
			console.error(error);
			dispatch(
				setToast({
					message: 'Error while adding connection',
					severity: Severity.alert,
					show: true,
				}),
			);
		},
	});

	/**
	 * Mutation for removing a connection from the composition.
	 */
	const { mutate: removeConnection } = useMutation({
		mutationFn: (connection: Connection) => {
			const inputNode = connection.input.node as ElementInstanceNode | null;

			const dto = {
				sourceInstanceId: inputNode?.data.elementInstance.id,
				sourceSocketId: connection.input.key,
			} as RemoveSocketConnectionDto;

			return CompositionBuilderService.removeSocketConnection(currentComposition?.id ?? '', dto);
		},
		onSuccess: () => {
			editor?.trigger('modified');
		},
		onError: (error, variables) => {
			console.error(error);
			dispatch(
				setToast({
					message: 'Error while removing connection',
					severity: Severity.alert,
					show: true,
				}),
			);
		},
	});

	/**
	 * Mutation for removing an element instance from the composition.
	 */
	const { mutate: removeElementInstance } = useMutation({
		mutationFn: (elementInstanceId: string) => {
			return CompositionBuilderService.removeInstance(currentComposition?.id ?? '', elementInstanceId);
		},
		onSuccess: (data, variables, context) => {
			var nodeToRemove = editor?.nodes.find((n: any) => n.data.elementInstance.id === variables);
			if (nodeToRemove) {
				isSilenced.current = true;
				editor?.removeNode(nodeToRemove);
				isSilenced.current = false;
			}
			editor?.trigger('modified');
		},
		onError: (error) => {
			console.error(error);
			dispatch(
				setToast({
					message: 'Error while removing node',
					severity: Severity.alert,
					show: true,
				}),
			);
		},
	});

	/**
	 * Imports a diagram into the editor. While importing, the custom event listeners are silenced.
	 */
	const importDiagram = async (diagramDocument?: string | null) => {
		isSilenced.current = true;
		await editor?.import(diagramDocument);
		isSilenced.current = false;
	};

	return {
		registerEditorModify,
		removeElementInstance,
		importDiagram,
		isImporting: isSilenced,
		selectedOpenSocket,
	};
};

export default useRete;
