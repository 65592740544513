import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronUp, faEdit, faPlus, faSave, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import {
	ObjectPropertyValue,
	PropertyEntryDto,
	SimplePropertyValue,
	TablePropertyDto,
	TablePropertyValue,
} from '../../../../../services/warehouse';
import TablePropertyEntryGrid from '../TablePropertyEntryGrid';
import ObjectEntryGrid from './ObjectEntryGrid';

interface Props {
	property: TablePropertyDto;
	propertyEntry: PropertyEntryDto;
	onChange: (value: PropertyEntryDto) => void;
	editMode: boolean;
}

const ObjectTableProperty = ({ property, propertyEntry, onChange, editMode }: Props) => {
	const [entry, setEntry] = useState<PropertyEntryDto>(propertyEntry);
	const [rows, setRows] = useState<number[]>([]);
	const [activeRow, setActiveRow] = useState<number | null>(null);
	const [isRowVisible, setIsRowVisible] = useState(true);

	useEffect(() => {
		if (!editMode) {
			setActiveRow(null);
		}
	}, [editMode]);

	useEffect(() => {
		onChange(entry);
	}, [entry]);

	useEffect(() => {
		try {
			const values = ((propertyEntry.value as ObjectPropertyValue).values?.[property.id!] as TablePropertyValue)
				.values;
			if (values) {
				const firstArray = values[Object.keys(values)[0]];
				if (Array.isArray(firstArray)) {
					const lengthOfArray = firstArray.length;
					setRows(Array.from(Array(lengthOfArray).keys()));
				}
			} else {
				setRows([]);
			}
		} catch (error: any) {
			setRows([]);
		}
	}, []);

	const handleAddRow = () => {
		const deepCopyOfPropertyEntry = JSON.parse(JSON.stringify(propertyEntry));
		const currentValues = (deepCopyOfPropertyEntry.value as ObjectPropertyValue).values || {};
		let tablePropertyValue: TablePropertyValue = currentValues[property.id!]
			? (currentValues[property.id!] as TablePropertyValue)
			: { discriminator: 'TablePropertyValue', values: {} };

		console.log('hier1');

		property.columnProperties?.forEach((column) => {
			if (column.id) {
				if (!tablePropertyValue.values![column.id]) {
					tablePropertyValue.values![column.id] = [];
				}
				tablePropertyValue.values![column.id].push({
					discriminator: 'SimplePropertyValue',
					value: '',
				} as SimplePropertyValue);
			}
		});

		const updatedValues = {
			...currentValues,
			[property.id!]: tablePropertyValue,
		};
		const updatedPropertyEntry = {
			...propertyEntry,
			value: {
				...(propertyEntry.value as ObjectPropertyValue),
				values: updatedValues,
			},
		};
		setEntry(updatedPropertyEntry);
		setRows((prevRows) => [...prevRows, prevRows.length]);
	};

	const handleRemoveRow = (rowIndex: number) => {
		const newEntry = JSON.parse(JSON.stringify(entry)) as PropertyEntryDto;

		var objectPropertyValue = newEntry.value as ObjectPropertyValue;

		var currentTableValues = objectPropertyValue.values![property.id!] as TablePropertyValue;

		property.columnProperties?.forEach((column) => {
			if (currentTableValues.values![column.id!] && currentTableValues.values![column.id!][rowIndex]) {
				currentTableValues.values![column.id!].splice(rowIndex, 1);
			}
		});

		objectPropertyValue.values![property.id!] = currentTableValues;

		newEntry.value = objectPropertyValue;

		setEntry(newEntry);
		setRows((prevRows) => {
			const copyRows = [...prevRows];
			copyRows.splice(rowIndex, 1);
			return copyRows;
		});
		if (activeRow === rowIndex) {
			setActiveRow(null);
		}
	};

	const toggleRowVisibility = () => {
		setIsRowVisible((prev) => !prev);
	};

	return (
		<table style={{ width: '100%' }}>
			<tbody>
				<tr>
					<td colSpan={4}>
						{property.name}
						<button style={{ float: 'right' }} onClick={toggleRowVisibility}>
							<FontAwesomeIcon
								icon={isRowVisible ? (faChevronUp as IconProp) : (faChevronDown as IconProp)}
							/>
						</button>
						{editMode && (
							<button style={{ float: 'right' }} disabled={activeRow !== null} onClick={handleAddRow}>
								<FontAwesomeIcon icon={faPlus as IconProp} swapOpacity />
							</button>
						)}
					</td>
				</tr>
				{isRowVisible && (
					<tr>
						<td colSpan={4}>
							<div style={{ width: '100%', overflowX: 'auto' }}>
								<table style={{ width: 'max-content' }}>
									<thead>
										<tr>
											{editMode && <th>Actions</th>}
											{property.columnProperties?.map((column, index) => (
												<th key={index} className='property-header-name'>
													{column.name}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{rows.map((rowNumber, rowIndex) => (
											<tr key={`${rowNumber}-${Math.random()}`}>
												{editMode && (
													<td>
														<button
															disabled={activeRow !== null && activeRow !== rowIndex}
															onClick={() => {
																if (activeRow === rowIndex) {
																	setActiveRow(null);
																} else {
																	setActiveRow(rowIndex);
																}
															}}
														>
															<FontAwesomeIcon
																icon={
																	activeRow === rowIndex
																		? (faSave as IconProp)
																		: (faEdit as IconProp)
																}
															/>
														</button>
														<button
															disabled={activeRow !== null}
															onClick={() => handleRemoveRow(rowIndex)}
														>
															<FontAwesomeIcon icon={faTrash as IconProp} />
														</button>
													</td>
												)}
												<ObjectEntryGrid
													columnProperties={property.columnProperties!}
													propertyId={property.id!}
													propertyEntry={entry}
													setEntry={setEntry}
													isActive={activeRow === rowIndex}
													index={rowIndex}
												/>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
export default ObjectTableProperty;
