// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.combinationsListBody {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  padding: 20px;
  height: 0;
  overflow: hidden;
}

.combinationEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/features/Editor/features/variantEditor/features/CombinationsList/CombinationsList.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,SAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ","sourcesContent":[".combinationsListBody {\n    display: flex;\n    flex-direction: column;\n    align-items: start;\n    flex-grow: 1;\n    padding: 20px;\n    height: 0;\n    overflow: hidden;\n}\n\n.combinationEntry {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
