import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import CameraController from './CameraController';
import { Canvas } from '@react-three/fiber';

import "../Viewer.scss";

interface FiberProps {
	gltf?: GLTF;
}

const FiberViewer = ({ gltf }: FiberProps) => {
	if (!gltf) return null;
	
	return (
		<Canvas>
			<CameraController />
			<ambientLight intensity={1} />
			<axesHelper scale={500} />
			<primitive object={gltf.scene} />
		</Canvas>
	);
};

export default FiberViewer;
