// Context
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Services
import { CombinationEntryDto, CompositionElementsDto, CompositionSimpleDto, OpenSocketDto } from '../services/composer';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { VariantOptions } from '../services/modelmanager';

export interface EditorReducerState {
	/**
	 * The path of compositions that are currently open in the working area.
	 */
	compositionPath: CompositionSimpleDto[];

	/**
	 * The elements of the composition that is currently open in the working area.
	 */
	compositionElements: CompositionElementsDto | null;

	/**
	 * Indicates if the working area has any modification, i.e. whether nodes have been added or removed, whether connections have been added or removed or whether nodes have been dragged.
	 */
	isModified: boolean;

	selectedOpenSocket: OpenSocketDto | null;

}

export const initialState: EditorReducerState = {
	compositionPath: [],
	compositionElements: null,
	isModified: false,
	selectedOpenSocket: null,
};

export const editorSlice = createSlice({
	name: 'editor',
	initialState,
	reducers: {
		setCompositionPath: (state, action: PayloadAction<CompositionSimpleDto[]>) => {
			state.compositionPath = action.payload;
		},
		setIsModified: (state, action: PayloadAction<boolean>) => {
			state.isModified = action.payload;
		},
		setSelectedOpenSocket: (state, action: PayloadAction<OpenSocketDto | null>) => {
			state.selectedOpenSocket = action.payload;
		},
		setCompositionElements: (state, action: PayloadAction<CompositionElementsDto | null>) => {
			state.compositionElements = action.payload;
		},
		addCompositionElementsCombinationEntry: (
			state,
			action: PayloadAction<{ combinationId: string; combinationEntry: CombinationEntryDto }>,
		) => {
			state.compositionElements?.combinations
				?.find((combination) => combination.id === action.payload.combinationId)
				?.entries?.push(action.payload.combinationEntry);
		},
		removeCompositionElementsCombinationEntry: (state, action: PayloadAction<string>) => {
			state.compositionElements?.combinations
				?.find((combination) => combination.id === action.payload)
				?.entries?.pop();
		},
	},
});

export const {
	setCompositionPath,
	setIsModified,
	setSelectedOpenSocket,
	setCompositionElements,
	addCompositionElementsCombinationEntry,
	removeCompositionElementsCombinationEntry,
} = editorSlice.actions;

export default editorSlice.reducer;
