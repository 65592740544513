/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GltfDto } from '../models/GltfDto';
import type { VariantOptions } from '../models/VariantOptions';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ModelsService {

    /**
     * @param compositionId 
     * @param isBuild 
     * @param requestBody 
     * @returns GltfDto Success
     * @throws ApiError
     */
    public static getGltf(
compositionId: string,
isBuild: boolean = false,
requestBody?: Array<VariantOptions>,
): CancelablePromise<GltfDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/models/{compositionId}/gltf',
            path: {
                'compositionId': compositionId,
            },
            query: {
                'isBuild': isBuild,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param compositionId 
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static downloadGltf(
compositionId: string,
requestBody?: Array<VariantOptions>,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/models/{compositionId}/gltf/download',
            path: {
                'compositionId': compositionId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
