import 'regenerator-runtime/runtime.js';
import { Component, Engine } from 'rete';
import AreaPlugin from 'rete-area-plugin';
import ConnectionPlugin from 'rete-connection-plugin';
import ReactRenderPlugin from 'rete-react-render-plugin';
import ElementInstanceComponent from './nodes/ElementInstanceComponent';
import { LiebherrReteEditor } from './LiebherrReteEditor';
import { NodeWrapper } from './nodes/NodeWrapper';

declare module 'rete/types/events' {
	interface EventsTypes {
		'modified': void;
		'arrange': void
	}
}

/**
 * Initializes a new ReteEditor
 * @param container Editor will be displayed here
 * @returns new created Editor.
 */
export function createEditor(container: HTMLDivElement) {
	let components = [new ElementInstanceComponent()];
	container.replaceChildren();

	let editor = new LiebherrReteEditor(container);

	editor.use(ConnectionPlugin);
	editor.use(ReactRenderPlugin, {
		component: (props: any) => (
			<NodeWrapper { ...props } />
		),
	});

	editor.use(AreaPlugin, {
		background: true,
		snap: false,
	});

	let engine = new Engine('demo@0.1.0');

	components.forEach((c: Component) => {
		editor.register(c);
		engine.register(c);
	});

	editor.bind("modified");

	/* Disables the doubleblick zoom */
	editor.on('zoom', ({ source }) => {
		return source !== 'dblclick';
	});

	editor.view.resize();
	editor.trigger('process');

	AreaPlugin.zoomAt(editor, editor.nodes);

	return editor;
}
