/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMappingDto } from '../models/CreateMappingDto';
import type { MappingDto } from '../models/MappingDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MappingsService {

    /**
     * Creates new mapping
     * @param classificationId class Id of the related class
     * @param propertyId property Id of the related property
     * @param requestBody mappingDto with parameter to safe in mapping
     * @returns MappingDto Success
     * @throws ApiError
     */
    public static addMapping(
classificationId: string,
propertyId: string,
requestBody?: CreateMappingDto,
): CancelablePromise<MappingDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/classifications/{classificationId}/properties/{propertyId}',
            path: {
                'classificationId': classificationId,
                'propertyId': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete an existing mapping
     * @param classificationId class Id of the related class
     * @param propertyId property Id of the related property
     * @returns void 
     * @throws ApiError
     */
    public static removeMapping(
classificationId: string,
propertyId: string,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/classifications/{classificationId}/properties/{propertyId}',
            path: {
                'classificationId': classificationId,
                'propertyId': propertyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets mapping by given classId and propertyId
     * @param classificationId class id of the related class
     * @param propertyId property id of the related property
     * @returns MappingDto Success
     * @throws ApiError
     */
    public static getMapping(
classificationId: string,
propertyId: string,
): CancelablePromise<MappingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/{classificationId}/properties/{propertyId}',
            path: {
                'classificationId': classificationId,
                'propertyId': propertyId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all Mapping that are related to a class directly or inherited
     * @param classificationId class id of the related class
     * @returns MappingDto Success
     * @throws ApiError
     */
    public static getMappingsByClass(
classificationId: string,
): CancelablePromise<Array<MappingDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/{classificationId}/mappings',
            path: {
                'classificationId': classificationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all Mapping that are related to a class directly
     * @param classificationId class id of the related class
     * @returns MappingDto Success
     * @throws ApiError
     */
    public static getRelatedMappings(
classificationId: string,
): CancelablePromise<Array<MappingDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/{classificationId}/mappings/related',
            path: {
                'classificationId': classificationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all Mappings that are inherited to class
     * @param classificationId class id of the related class
     * @returns MappingDto Success
     * @throws ApiError
     */
    public static getInheritedMapping(
classificationId: string,
): CancelablePromise<Array<MappingDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/classifications/{classificationId}/mappings/inherited',
            path: {
                'classificationId': classificationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
