import { useState } from 'react';
import EditorToolbar from './features/EditorToolbar';
import OpenSocketsPanel from './features/OpenSocketsPanel';
import ReadonlyLabel from './features/ReadonlyLabel';
import ReteEditor from './features/ReteEditor';
import { LiebherrReteEditor } from './rete/LiebherrReteEditor';

// Styling
import './WorkingArea.scss';

export type EditorProps = {
	editor: LiebherrReteEditor | null;
};

const WorkingArea = () => {
	const [editor, setEditor] = useState<LiebherrReteEditor | null>(null);

	return (
		<div className='relative flex-1'>
			<ReteEditor editor={editor} setEditor={(e: LiebherrReteEditor) => setEditor(e)} />
			<EditorToolbar editor={editor} />
			<ReadonlyLabel editor={editor} />
			<OpenSocketsPanel editor={editor} />
		</div>
	);
};

export default WorkingArea;
