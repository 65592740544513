import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import ToolbarButton from '../ToolbarButton';
import { EditorProps } from '../../../../WorkingArea';
import AddInstanceModal from '../../../../../../components/AddInstanceModal';
import useComponents from '../../../../../../../../hooks/useComponents';

const AddComponentInstanceToolbarButton = ({editor}: EditorProps) => {
	const { t } = useTranslate();
	const [show, setShow] = useState<boolean>(false);

	const {components, isLoading} = useComponents();

	return (
		<>
			<AddInstanceModal
				label={t('addComponentInstance', 'Add component instance')}
				elementBases={components??[]}
				isLoading={isLoading}
				show={show} 
				onClose={() => setShow(false)} 
				onSave={(data) => editor?.addElementInstance(data)} />

			<ToolbarButton
				tooltip={t('addComponentInstance', 'Add Component Instance')}
				icon={faGear}
				onClick={() => setShow(true)}
			/>
		</>
	);
};

export default AddComponentInstanceToolbarButton;
