// MUI
import {createTheme} from "@mui/material/styles";

export const liebherrTheme = createTheme({
	palette: {
		primary: {
			main: "#ffd000",
		},
		secondary: {
			main: "#524F53",
		},
		error: {
			main: "#f89939",
		},
		warning: {
			main: "#FFAF00",
		},
		success: {
			main: "#19AF37",
		},
		info: {
			main: "#676063",
		},
	}
});