import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faPlus, faSave, faTrash, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { TablePropertyDto } from '../../../../services/catalog';
import { PropertyUsageType } from '../../../../services/composer';
import { TablePropertyValue, PropertyEntryDto, SimplePropertyValue } from '../../../../services/warehouse';
import TablePropertyEntryGrid from './TablePropertyEntryGrid';

interface Props {
	property: TablePropertyDto;
	propertyEntry: PropertyEntryDto;
	onChange: (value: PropertyEntryDto) => void;
	editMode: boolean;
	getData: (data: boolean) => void;
}

const TablePropertyGrid = ({ property, propertyEntry, onChange, editMode, getData }: Props) => {
	//TODO: get bugfix for adding and removing rows from warehouse TablePropertyGrid
	const [entry, setEntry] = useState<PropertyEntryDto>(propertyEntry);
	const [rows, setRows] = useState<number[]>([]);
	const [activeRow, setActiveRow] = useState<number | null>(null);
	const [isRowVisible, setIsRowVisible] = useState(true);

	useEffect(() => {
		if (!editMode) {
			setActiveRow(null);
		}
	}, [editMode]);

	useEffect(() => {
		onChange(entry);
	}, [entry]);

	useEffect(() => {
		try {
			const values = (propertyEntry.value as TablePropertyValue).values;
			if (values) {
				const firstArray = values[Object.keys(values)[0]];
				if (Array.isArray(firstArray)) {
					const lengthOfArray = firstArray.length;
					setRows(Array.from(Array(lengthOfArray).keys()));
				}
			} else {
				setRows([]);
			}
		} catch (error: any) {
			setRows([]);
		}
	}, []);

	const handleAddRow = () => {
		const newEntry = JSON.parse(JSON.stringify(entry));
		if (!newEntry.value) {
			newEntry.value = { discriminator: 'TablePropertyValue', values: {} } as TablePropertyValue;
		}
		property.columnProperties?.forEach((column) => {
			if (column.id) {
				if (!newEntry.value.values[column.id]) {
					newEntry.value.values[column.id] = [];
				}
				newEntry.value.values[column.id].push({
					discriminator: 'SimplePropertyValue',
					value: null,
				} as SimplePropertyValue);
			}
		});

		setEntry(newEntry);
		setRows((prevRows) => [...prevRows, prevRows.length]);
	};

	const handleRemoveRow = (rowIndex: number) => {
		const newEntry = JSON.parse(JSON.stringify(entry));

		property.columnProperties?.forEach((column) => {
			if (newEntry.value!.values![column.id!] && newEntry.value!.values![column.id!][rowIndex]) {
				newEntry.value!.values![column.id!].splice(rowIndex, 1);
			}
		});
		setEntry(newEntry);
		setRows((prevRows) => {
			const copyRows = [...prevRows];
			copyRows.splice(rowIndex, 1);
			return copyRows;
		});
		if (activeRow === rowIndex) {
			setActiveRow(null);
		}
	};

	const toggleRowVisibility = () => {
		setIsRowVisible((prev) => !prev);
	};

	return (
		<table style={{ width: '100%' }}>
			<tbody>
				<tr>
					<td colSpan={4}>
						{property.name + ' | ' + entry.propertyUsageType}
						<button style={{ float: 'right' }} onClick={toggleRowVisibility}>
							<FontAwesomeIcon
								icon={isRowVisible ? (faChevronUp as IconProp) : (faChevronDown as IconProp)}
							/>
						</button>
						{editMode && (
							<button style={{ float: 'right' }} onClick={handleAddRow}>
								<FontAwesomeIcon icon={faPlus as IconProp} swapOpacity />
							</button>
						)}
					</td>
				</tr>
				{isRowVisible && (
					<tr>
						<td colSpan={4}>
							<div style={{ width: '100%', overflowX: 'auto' }}>
								<table style={{ width: 'max-content' }}>
									<thead>
										<tr>
											{editMode && <th>Actions</th>}
											{property.columnProperties?.map((column, index) => (
												<th key={index} className='property-header-name'>
													{column.name}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{rows.map((rowNumber, rowIndex) => (
											<tr key={rowNumber}>
												{editMode && (
													<td>
														<button
															disabled={activeRow !== null && activeRow !== rowIndex}
															onClick={() => {
																if (activeRow === rowIndex) {
																	setActiveRow(null);
																	getData(false);
																} else {
																	setActiveRow(rowIndex);
																	getData(true);
																}
															}}
														>
															<FontAwesomeIcon
																icon={
																	activeRow === rowIndex
																		? (faSave as IconProp)
																		: (faEdit as IconProp)
																}
															/>
														</button>
														<button onClick={() => handleRemoveRow(rowIndex)}>
															<FontAwesomeIcon icon={faTrash as IconProp} />
														</button>
													</td>
												)}
												<TablePropertyEntryGrid
													columnProperties={property.columnProperties!}
													propertyEntry={entry}
													setEntry={setEntry}
													isActive={activeRow === rowIndex}
													index={rowIndex}
												/>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};
export default TablePropertyGrid;
