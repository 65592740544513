import { useEffect, useState } from 'react';
import { useAppSelector } from '../../context/hooks';
import { RootState } from '../../context/store';
import { ElementBaseDiscriminator } from '../../services/composer';
import DividerToolbar from './components/DividerToolbar';
import EditorHeader from './components/EditorHeader';
import { ResizeableViewer } from './components/ResizeableViewer';
import VariantEditor from './features/variantEditor/VariantEditor';
import WorkingArea from './features/workingArea/WorkingArea';
import useEditor from './hooks/useEditor';

// Styling
import './Editor.scss';

export const Editor = () => {
	const activeComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const { currentComposition, resetPath } = useEditor();

	const [viewerSize, setViewerSize] = useState<number>(0);

	useEffect(() => {
		resetPath(activeComposition?.id ?? undefined);
	}, [activeComposition]);

	return (
		<div className='h-100 flex flex-row'>
			<div className='h-100 d-flex flex-column flex-grow gap-1 p-2'>
				<EditorHeader />
				{currentComposition && currentComposition?.discriminator === ElementBaseDiscriminator.Composition ? (
					<WorkingArea />
				) : (
					currentComposition?.discriminator === ElementBaseDiscriminator.CompositionVariant && <VariantEditor />
				)}
			</div>
			<DividerToolbar viewerSize={viewerSize} setViewerSize={setViewerSize} />
			{activeComposition?.id && <ResizeableViewer viewerSize={viewerSize} compositionId={activeComposition?.id} />}
		</div>
	);
};
