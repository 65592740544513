import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import VariantEditorHeader from '../../components/VariantEditorHeader/VariantEditorHeader';
import useVariantEditor from '../../hooks/useVariantEditor';
import ElementNode from './ElementNode';
import AddElementMenu from './components/AddElementMenu';

// Styles
import './ElementsList.scss';
import StatusDisplay from '../../../../../../components/StatusDisplay';

type Props = {
	isLoading: boolean;
	className?: string;
	style?: React.CSSProperties;
};

const ElementsList = ({ isLoading, className, style }: Props) => {
	const { t } = useTranslate();
	const { compositionElements, addElement } = useVariantEditor();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const onOpenAddElementDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const onCloseAddElementDialog = () => {
		setAnchorEl(null);
	};

	return (
		<div className={className + ' flex flex-col'} style={{ ...style, borderRight: '2px solid #e0e0e0' }}>
			<VariantEditorHeader title='Elements' buttonLabel='Add' onCreate={onOpenAddElementDialog} />
			<div className='flex-1 px-12 py-2'>
				<StatusDisplay isLoading={isLoading} isInformation={compositionElements?.contains?.length === 0} information={t('noElements')}>
					<>
						{compositionElements?.contains?.map((element) => (
							<ElementNode element={element} key={element.id} />
						))}
					</>
				</StatusDisplay>
			</div>
			<AddElementMenu anchorElement={anchorEl} onAdd={addElement} onClose={onCloseAddElementDialog} />
		</div>
	);
};

export default ElementsList;
