// REACT
import { useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { setActionMode } from '../../../context/appReducer';
import { useDispatch } from 'react-redux';
import { setActiveComposition } from '../../../context/compositionsReducer';
// COMPONENTS
import { Col, Modal, Row } from 'react-bootstrap';
import { Text } from '@lbc-toolkit/text';
import DetailsGeneral from './DetailsGeneral';
import DetailsPropertyGrid from './DetailsPropertyGrid';
// STYLING
import './DetailsModal.scss';
// MODELS
import { ActionModeType, Severity } from '../../../models/models';
// TRANSLATIONS
import { useTranslate } from '@tolgee/react';
// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit } from '@fortawesome/pro-regular-svg-icons';

const DetailsModal = () => {
	const { t } = useTranslate();
	const dispatch = useDispatch();
	const actionMode = useAppSelector((state: RootState) => state.app.actionMode);

	/**
	 * Closes model dialog
	 */
	const onClose = () => {
		dispatch(setActiveComposition(null));
		dispatch(setActionMode(ActionModeType.None));
	};

	return (
		<Modal dialogClassName='details-modal-layout' centered show={actionMode === ActionModeType.Details}>
			<Modal.Header>
				<Modal.Title>
					<Text type='h2'>{t('compDetails')}</Text>
					<FontAwesomeIcon className='px-1 clickable modal-button-close' icon={faClose} onClick={onClose} />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='details-body'>
				<div className='details-content'>
					<Row className='details-modal-row'>
						<Col>
							<DetailsGeneral />
						</Col>
						<Col>
							<DetailsPropertyGrid />
						</Col>
					</Row>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default DetailsModal;
