import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { TablePropertyValue, SimplePropertyValue } from '../../../../../services/composer';

type TextPropertyProps = {
	editMode: boolean;
	value: TablePropertyValue | SimplePropertyValue | null | undefined;
	onChange: (value: string) => void;
};

const TextProperty = ({ editMode, value, onChange }: TextPropertyProps) => {
	const [inputValue, setInputValue] = useState<string>("")
	const { t } = useTranslate();

	useEffect(() => {
		if (value && 'value' in value) {
			const actualValue = (value as SimplePropertyValue).value;
			setInputValue(actualValue ?? "");
		}
	}, [value])

	return editMode ? (
		<>
			<input
				type={'text'}
				value={inputValue}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				className='form-control form-control-sm'
			/>
			{/* <TextInput placeholder={t('enterValue')} value={value} onChange={onChange} /> */}
		</>
	) : Boolean(inputValue) ? (
		inputValue
	) : (
		t('noValue')
	);
};

export default TextProperty;
