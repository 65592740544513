import { PropertyGridPropertyProps } from "../PropertyGridProperty";
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataType, SimplePropertyDto } from '../../../../../services/catalog';
import { PropertyUsageType, SimplePropertyValue } from '../../../../../services/warehouse';
import NumberProperty from '../propertyDataType/NumberProperty';
import TextProperty from '../propertyDataType/TextProperty';
import { useTranslate } from "@tolgee/react";

type SimplePropertyProps = {
    property: SimplePropertyDto
} & PropertyGridPropertyProps

const SimpleProperty = ({
    editMode,
	selected,
	property,
	propertyEntry,
	selectedPropertyType,
	onChange,
	onClick,
}: SimplePropertyProps) => {
	const [isCorrectType] = useState<boolean>(propertyEntry?.propertyUsageType === selectedPropertyType)
	const [isPropertyType] = useState<boolean>(propertyEntry?.propertyUsageType === PropertyUsageType.Type);
	const [isPropertyTypeInstance] = useState<boolean>(propertyEntry?.propertyUsageType === PropertyUsageType.Instance);

	/**
	 * called if any input field have a change
	 */
	 const changeValue = (value: string) => {
		var tempPropertyValue = { ...propertyEntry };

		if (!tempPropertyValue.value) {
			tempPropertyValue.value = {
				discriminator: "SimplePropertyValue",
				value: value
			} as SimplePropertyValue;
		} else {
			tempPropertyValue.value = {
				...tempPropertyValue.value,
				value: value
			} as SimplePropertyValue;
		}
		onChange(tempPropertyValue);
	};

	/**
	 * render inputField or value as string depending on editMode
	 * which inputType is shown depends on property's datatype
	 */
	const renderValueField = () => {
		const props = {
			editMode: editMode,
			property: property,
			value: propertyEntry?.value,
			unit: property.unit ?? undefined,
			onChange: changeValue,
		};
		switch (property.dataType) {
			case DataType.Integer:
				return NumberProperty({ ...props, editMode: editMode && isCorrectType, decimal: false });
			case DataType.Double:
				return NumberProperty({ ...props, editMode: editMode && isCorrectType, decimal: true });
			case DataType.String:
				return TextProperty({...props, editMode: editMode && isCorrectType});
			case DataType.Boolean:
				//return BooleanProperty(props);
			default:
				return;
		}
	};

	return (
		<tr onClick={onClick} className={`propertygrid-property ${selected ? 'selected' : ''}`}>
			<td className={`${isCorrectType ? '' : 'propertygrid-cell-disable'}`}>
				<>{renderValueField()}</>
			</td>
		</tr>
	);
};

export default SimpleProperty;