import useVariantEditor from '../../hooks/useVariantEditor';
import useEditor from '../../../../hooks/useEditor';
import Node from '../../../../components/Node/Node';
import { ConnectorDirection, ElementInstanceDto, SocketDto } from '../../../../../../services/composer';

interface Props {
	element: ElementInstanceDto;
}

const ElementNode = ({ element }: Props) => {
	const { removeElement, isElementInCombinations } = useVariantEditor();
	const { navigateToComposition } = useEditor();
	const elementInCombinations = isElementInCombinations(element.id ? element.id : '');

	return (
		<Node
			elementInstance={element}
			isReadonly={elementInCombinations}
			onRemove={() => removeElement(element)}
			onNavigate={navigateToComposition}
		>
			<>
				{element.sockets
					?.filter((socket: SocketDto) => socket.direction === ConnectorDirection.In)
					.map((socket: SocketDto) => {
						return (
							<div key={socket.id} className='input'>
                                <div className='socket input'/>
								<div className='input-title'>{socket.connectorName}</div>
							</div>
						);
					})}
				{element.sockets
					?.filter((socket: SocketDto) => socket.direction === ConnectorDirection.Out)
					.map((socket: SocketDto) => {
                        return (
                            <div key={socket.id} className='output'>
								<div className='input-title'>{socket.connectorName}</div>
                                <div className='socket output' />
							</div>
						);
					})}
			</>
		</Node>
	);
};
export default ElementNode;
