import React, { useEffect, useState } from 'react';
// COMPONENTS
import { Col, Row } from 'react-bootstrap';
import { Text } from '@lbc-toolkit/text';
// STYLING
import './DetailsModal.scss';
// MODELS
import { ActionModeType, Severity } from '../../../models/models';
// ICONS
import { faCancel, faCheck, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//REDUX
import { setToast } from '../../../context/appReducer';
import { useAppDispatch, useAppSelector } from '../../../context/hooks';
import { RootState } from '../../../context/store';
import { setActiveComposition, setCompositionsListNeedsReload } from '../../../context/compositionsReducer';
// TRANSLATIONS
import { useTranslate } from '@tolgee/react';
// API
import {
	CompositionSimpleDto,
	CompositionsService,
	PropertyUsageType,
	Status,
	UpdateCompositionDto,
} from '../../../services/composer';
import { PropertyCollectionDto } from '../../../services/warehouse';
import PropertyGrid from './propertyGrid/PropertyGrid';
import { PropertyDto } from '../../../services/catalog';
import { useMutation, useQuery } from '@tanstack/react-query';

const DetailsPropertyGrid = () => {
	const { t } = useTranslate();
	const dispatch = useAppDispatch();
	const activeComposition = useAppSelector((state: RootState) => state.compositions.activeComposition);
	const [usage, setUsage] = useState<string>(t('selectPropertiesToShowUsage'));
	const [actionMode, setActionMode] = useState<ActionModeType>(ActionModeType.Details);
	const [selectedProperty, setSelectedProperty] = useState<PropertyDto | null>(null);
	const [propertyCollection, setPropertyCollection] = useState<PropertyCollectionDto | null>(null);
	const [isBeingUpdated, setIsBeingUpdated] = useState<boolean>(false);

	const { mutate: fetchPropertyCollection, isPending: pendingProperties } = useMutation({
		mutationFn: (id: string) => CompositionsService.getCompositionProperties(id),
		onSuccess: (data) => {
			setPropertyCollection(data);
		},
	})
	useEffect(() => {
		if (activeComposition?.id) {
			fetchPropertyCollection(activeComposition.id!);
		}
	}, [activeComposition]);

	/**
	 * Callback function which is triggered
	 * when a value of property in grid changed
	 */
	const onValueChanged = (propertyCollection: PropertyCollectionDto) => {
		setPropertyCollection(propertyCollection);
	};

	/**
	 * Starts edit
	 */
	const onEdit = () => {
		setActionMode(ActionModeType.Edit);
	};

	/**
	 * Cancels edit
	 */
	const onCancel = () => {
		setActionMode(ActionModeType.Details);
	};

	/**
	 * Saves changes and updates composition on database
	 */
	const onCheck = () => {
		setActionMode(ActionModeType.Details);
		const updateComposition: UpdateCompositionDto = {
			id: activeComposition?.id!,
			name: activeComposition?.name!,
			description: activeComposition?.description!,
			status: activeComposition?.status!,
			classificationId: activeComposition?.classificationId!,
			propertyCollection: propertyCollection!,
		};
		console.log(updateComposition);
		dispatch(setActiveComposition(activeComposition));
		CompositionsService.updateComposition(updateComposition)
			.then(() => {
				dispatch(setCompositionsListNeedsReload(true));
				dispatch(
					setToast({
						show: true,
						severity: Severity.success,
						message: t('compositionUpdated'),
					}),
				);
			})
			.catch((error: any) => {
				dispatch(
					setToast({
						show: true,
						severity: Severity.alert,
						message: t('couldNotSaved'),
					}),
				);
			});
		setActionMode(ActionModeType.Details);
	};

	const updateIsBeingUpdated = (data: boolean) => {
		setIsBeingUpdated(data);
	};

	return (
		<div className='details-card'>
			<Row className='px-4 py-2'>
				<Col sm={10} className='px-0'>
					<Text className='details-card-header' type='h3'>
						{t('properties')}
					</Text>
				</Col>
				<Col sm={2} className='px-0'>
					{activeComposition?.status === Status.Draft && (
						<div className='general-params-edit'>
							{actionMode === ActionModeType.Edit && !isBeingUpdated ? (
								<Row className='px-0'>
									<Col sm={6}>
										<FontAwesomeIcon
											className='px-1 clickable'
											icon={faCancel}
											onClick={onCancel}
										/>
									</Col>
									<Col sm={6}>
										<FontAwesomeIcon
											className='px-1 clickable modal-card-edit-btn'
											icon={faCheck}
											onClick={onCheck}
										/>
									</Col>
								</Row>
							) : (
								<FontAwesomeIcon
									className='px-1 clickable modal-card-edit-btn'
									icon={faPen}
									onClick={onEdit}
								/>
							)}
						</div>
					)}
				</Col>
			</Row>
			<div className='details-card-datagrid'>
				<div className='details-datagrid-table'>
					<table className='table table-bordered'>
						{pendingProperties ? "Loading...": (

							propertyCollection &&
								propertyCollection.propertySetEntries &&
								propertyCollection.propertySetEntries?.length > 0 &&
								propertyCollection.classificationId ? (
									<tbody>
									<PropertyGrid
									editMode={actionMode === ActionModeType.Edit}
									onChange={onValueChanged}
									propertyCollection={propertyCollection}
									propertyType={PropertyUsageType.Type}
									selectedProperty={selectedProperty}
									onSelectProperty={setSelectedProperty}
									getData={updateIsBeingUpdated}
									/>
									</tbody>
								) : (
									<tr>
								<td>{t('noProperties')}</td>
							</tr>
						)
					)}
					</table>
				</div>
			</div>
			<div className='details-datagrid-body-usage'>{usage}</div>
		</div>
	);
};

export default DetailsPropertyGrid;
