import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleLeft, faAngleRight, faCubes, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DividerToolbarProps = {
	viewerSize: number;
	setViewerSize: (size: number) => void;
};

const DividerToolbar = ({ viewerSize, setViewerSize }: DividerToolbarProps) => {
	const onToggle = () => {
		if (viewerSize === 0) setViewerSize(1);
		else setViewerSize(0);
	};

	const onSize = () => {
		if (viewerSize === 1) setViewerSize(2);
		else setViewerSize(1);
	};
	return (
		<div
			className='h-100 flex w-12 flex-col items-stretch justify-end bg-color-white'
			style={{
				borderRight: '1px solid #c7c6c5',
				borderLeft: '1px solid #c7c6c5',
			}}
		>
			<button className='flex flex-grow flex-col items-center justify-between py-2' onClick={onToggle}>
				<FontAwesomeIcon className='hover-grow' size='lg' icon={faCubes as IconProp} />
				<FontAwesomeIcon
					className='hover-grow'
					icon={(viewerSize ? faAngleRight : faAngleLeft) as IconProp}
					size='2x'
				/>
			</button>
			{Boolean(viewerSize) && (
				<button onClick={onSize} className='py-2'>
					<FontAwesomeIcon
						className='hover-grow'
						icon={(viewerSize === 1 ? faPlus : faMinus) as IconProp}
						size='2x'
					/>
				</button>
			)}
		</div>
	);
};

export default DividerToolbar;
