// THREE;
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// REACT
import { useEffect, useMemo } from 'react';
import { useThree } from '@react-three/fiber';

const CameraController = () => {
	const { camera, gl } = useThree();

	const initialCameraPosition = useMemo(() => {
		return new THREE.Vector3(-10 * Math.sin(0.2 * Math.PI), 2, 10 * Math.cos(0.2 * Math.PI));
	}, []);

	useEffect(() => {
		camera.position.set(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z);
	}, [camera, initialCameraPosition]);

	useEffect(() => {
		const controls = new OrbitControls(camera, gl.domElement);
		controls.autoRotate = true;
		return () => {
			controls.dispose();
		};
	}, [camera, gl]);

	return null;
};

export default CameraController;
