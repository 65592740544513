/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompositionSimpleDto } from '../models/CompositionSimpleDto';
import type { CompositionVariantSimpleDto } from '../models/CompositionVariantSimpleDto';
import type { UpdateCompositionVariantDetailsDto } from '../models/UpdateCompositionVariantDetailsDto';
import type { UpdateCompositionVariantDto } from '../models/UpdateCompositionVariantDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompositionVariantsService {

    /**
     * Updates an existing composition variant.
     * @param requestBody Update composition variant input
     * @returns any Created
     * @throws ApiError
     */
    public static updateCompositionVariant(
requestBody?: (UpdateCompositionVariantDto | UpdateCompositionVariantDetailsDto),
): CancelablePromise<(CompositionSimpleDto | CompositionVariantSimpleDto)> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/compositions/variant',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
