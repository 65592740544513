import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { EditorProps } from '../../WorkingArea';
import AddComponentInstanceToolbarButton from './components/addComponentInstance/AddComponentInstanceToolbarButton';
import AddCompositionInstanceToolbarButton from './components/addCompositionInstance/AddCompositionInstanceToolbarButton';

import useEditor from '../../../../hooks/useEditor';
import './styles/editorToolbar.scss';

type EditorToolbarProps = {} & EditorProps;

/**
 * Renders the toolbar for the editor.
 */
export const EditorToolbar = ({ editor }: EditorToolbarProps) => {
	const { isReadonly } = useEditor();

	return (
		<ButtonToolbar className='button-toolbar-container absolute'>
			<ButtonGroup aria-label='First-group' vertical>
				{!isReadonly && <AddComponentInstanceToolbarButton editor={editor} />}
				{!isReadonly && <AddCompositionInstanceToolbarButton editor={editor} />}
			</ButtonGroup>
		</ButtonToolbar>
	);
};
